import React, { useState, useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import BlogNavigation from './BlogNavigation';

const Blog = () => {
  const [articles, setArticles] = useState([]);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get('https://mjondol.co.za:4000/posts');
        setArticles(response.data);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();

    const fetchUserStatus = async () => {
      const userId = localStorage.getItem('userId');
      if (userId) {
        try {
          const response = await axios.get(`https://mjondol.co.za:4000/user-status/${userId}`);
          const { BlogUser } = response.data;
          setIsAdmin(BlogUser === 'True');
        } catch (error) {
          console.error('Error fetching user status:', error);
        }
      }
    };

    fetchUserStatus();

    const loggedIn = localStorage.getItem('loggedIn');
    const username = localStorage.getItem('username');

    // Log the details being read from local storage
    console.log('Reading from local storage:');
    console.log('loggedIn:', loggedIn);
    console.log('username:', username);

    if (loggedIn === 'true' && username) {
      setUser(username);
    }
  }, []);

  const extractDescription = (content) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(content, 'text/html');
    const pTag = doc.querySelector('p');
    const description = pTag ? pTag.textContent.slice(0, 100) : '';
    return description.length === 100 ? description + '...' : description;
  };

  return (
    <div className="blog-content">
      <BlogNavigation user={user} isAdmin={isAdmin} />

      <div className="blog-main-content">
        {articles.length > 0 && (
          <div className="card-pair-one">
            {articles[0] && (
              <Link to={`/article/${articles[0].ArticleId}`} id="main-card">
                <img src={articles[0].media} id="main-card-image" alt={articles[0].Title} />
                <div id="overlay">
                  <h2 id="main-card-title">{articles[0].Title}</h2>
                  <p id="main-card-description">{extractDescription(articles[0].Content)}</p>
                  <p id="main-card-time">{moment(articles[0].createdAt).fromNow()}</p>
                </div>
              </Link>
            )}

            <div className="main-card-friends">
              {articles[1] && (
                <div id="main-card-friend-one">
                  <Link to={`/article/${articles[1].ArticleId}`}>
                    <img src={articles[1].media} alt={articles[1].Title} />
                    <h4>{articles[1].Title.length > 25 ? articles[1].Title.substring(0, 25) + '...' : articles[1].Title}</h4>
                    <p>{moment(articles[1].createdAt).fromNow()}</p>
                  </Link>
                </div>
              )}
              {articles[2] && (
                <div id="main-card-friend-two">
                  <Link to={`/article/${articles[2].ArticleId}`}>
                    <img src={articles[2].media} alt={articles[2].Title} />
                    <h4>{articles[2].Title.length > 25 ? articles[2].Title.substring(0, 25) + '...' : articles[2].Title}</h4>
                    <p>{moment(articles[2].createdAt).fromNow()}</p>
                  </Link>
                </div>
              )}
            </div>
          </div>
        )}

        {articles.slice(3).map((article) => (
          <Link key={article.ArticleId} to={`/article/${article.ArticleId}`} className="card-series">
            <div id="card-series-image-container">
              <img src={article.media} alt={article.Title} />
            </div>
            <div className="card-series-details">
              <h3>{article.Title.slice(0, 56)}{article.Title.length > 56 ? '...' : ''}</h3>
              <p id="card-series-description">{extractDescription(article.Content)}</p>
              <p id="card-series-time">{moment(article.createdAt).fromNow()}</p>
            </div>
          </Link>
        ))}
      </div>
      <Outlet />
    </div>
  );
};

export default Blog;
