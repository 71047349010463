import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const logo = require('./logo.png');

const TicStage2 = () => {
  const [board, setBoard] = useState(Array(16).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isXNext) {
      const aiMove = getAIMove(board);
      if (aiMove !== null) {
        setTimeout(() => handleClick(aiMove), 1000); // Add a delay of 1 second
      }
    }
  }, [isXNext]);

  const handleClick = (index) => {
    if (board[index] || calculateWinner(board)) return;

    const newBoard = board.slice();
    newBoard[index] = isXNext ? 'X' : 'O';
    setBoard(newBoard);
    setIsXNext(!isXNext);
  };

  const getAIMove = (board) => {
    const randomMove = () => {
      const availableMoves = board.map((value, index) => (value === null ? index : null)).filter((value) => value !== null);
      return availableMoves[Math.floor(Math.random() * availableMoves.length)];
    };

    const calculatedMove = () => {
      const lines = [
        [0, 1, 2, 3],
        [4, 5, 6, 7],
        [8, 9, 10, 11],
        [12, 13, 14, 15],
        [0, 4, 8, 12],
        [1, 5, 9, 13],
        [2, 6, 10, 14],
        [3, 7, 11, 15],
        [0, 5, 10, 15],
        [3, 6, 9, 12],
      ];

      // Check if AI can win
      for (let i = 0; i < lines.length; i++) {
        const [a, b, c, d] = lines[i];
        if (board[a] === 'O' && board[a] === board[b] && !board[c]) return c;
        if (board[a] === 'O' && board[a] === board[c] && !board[b]) return b;
        if (board[b] === 'O' && board[b] === board[c] && !board[a]) return a;
        if (board[c] === 'O' && board[c] === board[d] && !board[a]) return a;
      }

      // Block opponent's winning move
      for (let i = 0; i < lines.length; i++) {
        const [a, b, c, d] = lines[i];
        if (board[a] === 'X' && board[a] === board[b] && !board[c]) return c;
        if (board[a] === 'X' && board[a] === board[c] && !board[b]) return b;
        if (board[b] === 'X' && board[b] === board[c] && !board[a]) return a;
        if (board[c] === 'X' && board[c] === board[d] && !board[a]) return a;
      }

      // Take the center if available
      if (!board[5]) return 5;

      // Take any available spot
      for (let i = 0; i < board.length; i++) {
        if (board[i] === null) {
          return i;
        }
      }
      return null;
    };

    const randomThreshold = Math.random();
    if (randomThreshold < 0.5) {
      return randomMove();
    } else {
      return calculatedMove();
    }
  };

  const calculateWinner = (board) => {
    const lines = [
      [0, 1, 2, 3],
      [4, 5, 6, 7],
      [8, 9, 10, 11],
      [12, 13, 14, 15],
      [0, 4, 8, 12],
      [1, 5, 9, 13],
      [2, 6, 10, 14],
      [3, 7, 11, 15],
      [0, 5, 10, 15],
      [3, 6, 9, 12],
    ];

    for (let i = 0; i < lines.length; i++) {
      const [a, b, c, d] = lines[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c] && board[a] === board[d]) {
        return board[a];
      }
    }
    return null;
  };

  const winner = calculateWinner(board);
  const status = winner ? `Winner: ${winner}` : `Next player: ${isXNext ? 'X' : 'O'}`;

  useEffect(() => {
    if (winner === 'X') {
      const userId = localStorage.getItem('userId');
      if (userId) {
        axios.post('https://mjondol.co.za:4000/updateStage', { userId, stage: 2 })
          .then(response => {
            console.log(response.data);
            setTimeout(() => {
              navigate('/games/tic-tac-toe/stage3');
            }, 2000); // Add a delay before moving to the next stage
          })
          .catch(error => {
            console.error('Error updating stage:', error);
          });
      } else {
        navigate('/games/tic-tac-toe/stage3'); // Bypass DB logic and proceed to next stage if user ID is null
      }
    }
  }, [winner, navigate]);

  const handleReset = () => {
    setBoard(Array(16).fill(null));
    setIsXNext(true);
  };

  return (
    <div className="tic-tac-toe">

      {/* Add a header */}
      <div className="tic-tac-toe-navigation">
        <div id="tic-tac-toe-exit">
          <p><Link to="/games">Home</Link></p>
        </div>

        <div id="tic-tac-toe-logo-container">
          <img src={logo} id="tic-tac-toe-logo" alt="Logo" />
        </div>

        <div id="navigation-space-filler">
        </div>
      </div>

      {/* Add body of game */}
      <div className="tic-tac-toe-center">

        {/* Display stages of the game */}
        <div className="tic-tac-toe-stages-display">
          <p id="tic-tac-toe-stage-label">Stage</p>
          <Link to="/games/tic-tac-toe/stage1"><p id="tic-tac-toe-stage-won">One</p></Link>
          <p id="tic-tac-toe-stage-active">Two</p>
          <p id="tic-tac-toe-stage-inactive">Three</p>
          <p id="tic-tac-toe-stage-inactive">Four</p>
          <p id="tic-tac-toe-stage-inactive">Five</p>
        </div>

        <div className="status">{status}</div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 80px)', gridTemplateRows: 'repeat(4, 80px)', gap: '5px' }}>
          {board.map((value, index) => (
            <button key={index} className="square" onClick={() => handleClick(index)} style={{ width: '100%', height: '100%', fontSize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {value}
            </button>
          ))}
        </div>
        <button className="reset-button" onClick={handleReset} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Reset</button>
      </div>
    </div>
  );
};

export default TicStage2;
