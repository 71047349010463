import React, { useEffect } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import GoogleAdComponent from './GoogleAdComponent';

const Games = () => {
  const location = useLocation();

  // Check if the current path is exactly "/games" or not
  const isGamesPath = location.pathname === '/games';

  return (
    <div className="games-body-content">
      {/* Display ads */}
      <GoogleAdComponent />
      
      {isGamesPath && (
        <div className="games-cards">
          {/* The tic tac toe game card */}
          <Link to="tic-tac-toe">
            <div className="games-cards-child" id="tic-tac-toe">
              <p>Tic Tac Toe</p>
            </div>
          </Link>

          {/* The polymath game card */}
          <Link to="polymath">
            <div className="games-cards-child" id="polymath">
              <p>Polymath</p>
            </div>
          </Link>
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default Games;
