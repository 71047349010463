import React from 'react';

const MjondolSocial = () => {
  return (
    <div className="mjondol-landing-content">
      <h1>Mjondol Social</h1>
      <p>Coming very soon!</p>
    </div>
  );
};

export default MjondolSocial;
