import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
        <div className="footer">
          <div className="declarations">
            <div id="declarations">
              <p>

              </p>
              <p>Mjondol Labs is a trading name of <br />Machete Capital (Pty) Ltd</p>
              <p>&#xA9; 2024, Machete Capital (Pty) Ltd</p>
            </div>
            <div id="contact">
              <p>Contact Us</p>
              <p>
                <a href="mailto:info@mjondol.co.za">Contact Support</a>
              </p>
              <p>
                Thornhill Office Park
                <br />
                84 Bekker Rd, Vorna Valley
                <br />
                Midrand, 1686
                <br />
                Gauteng, South Africa
              </p>
            </div>
          </div>
          <div className="socials">
            <div id="socials">
              <p>Connect</p>
              <p>
                <a href="https://www.linkedin.com/company/mjondol-labs/">LinkedIn</a>
              </p>
              {/*<p>
                <a href="">Instagram</a>
              </p>*/}
              <p>
                <a href="https://x.com/MjondolLabs">X / Twitter</a>
              </p>
              <p>
                <a href="https://web.facebook.com/MjondolLabs/">Facebook</a>
              </p>

              <br />
            </div>
            <div id="legals">
              <p>Legal</p>
              <p>
                <Link to="/cookies">Cookies Policy</Link>
              </p>
              <p>
                <Link to="/privacy">Privacy Policy</Link>
              </p>
              <p>
                <Link to="/disclaimer">Disclaimer</Link>
              </p>
              <p>
                <a href="https://app.termly.io/notify/f5a0fdf0-96fe-4aaa-b87d-1eaba0de8f13">DSAR form</a>
              </p>
              <p>
                <a href="https://app.termly.io/notify/f5a0fdf0-96fe-4aaa-b87d-1eaba0de8f13">CCPA California</a>
              </p>
              <p>
                <a href="#" class="termly-display-preferences">Consent Preferences</a>
              </p>
            </div>
          </div>
        </div>
  );
};

export default Footer;
