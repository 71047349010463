import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const BlogList = () => {
  const [posts, setPosts] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const response = await axios.get('https://mjondol.co.za:4000/posts');
        setPosts(response.data);
      } catch (error) {
        console.error("Error fetching posts:", error);
      }
    };
    fetchPosts();
  }, []);

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`https://mjondol.co.za:4000/posts/${postId}`);
      setPosts(posts.filter(post => post.ArticleId !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleEdit = (post) => {
    navigate('/blog-admin/edit-post', { state: { post } });
  };

  return (
    <div>
      <h2>Blog Posts</h2>
      <ul className="blog-list">
        {posts.map((post) => (
          <li key={post.ArticleId} className="blog-item">
            {post.media && <img src={post.media} alt={post.Title} className="blog-image" />}
            <div className="blog-details">
              <h4>{post.Title}</h4>
              <p className="post-date">{new Date(post.createdAt).toString()}</p> {/* Add CSS class for the date */}
            </div>

            <div className="bloglist-button-container">
              <button className="edit-button" onClick={() => handleEdit(post)}>Edit</button>
              <button className="delete-button" onClick={() => handleDelete(post.ArticleId)}>Delete</button>
            </div>

          </li>
        ))}
      </ul>
    </div>
  );
};

export default BlogList;
