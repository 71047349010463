import React, { useState, useRef, useEffect } from 'react';
import GoogleAdComponent from '../GoogleAdComponent';
import { Helmet } from 'react-helmet';

const AIImageEditor = () => {
  const [images, setImages] = useState([]);
  const [brightness, setBrightness] = useState(0);
  const [contrast, setContrast] = useState(100);
  const [saturation, setSaturation] = useState(100);
  const [noise, setNoise] = useState(0);
  const [grayscale, setGrayscale] = useState(0);
  const [sepia, setSepia] = useState(false);
  const [invert, setInvert] = useState(false);
  const [blur, setBlur] = useState(0);
  const [hue, setHue] = useState(0);
  const [vibrance, setVibrance] = useState(100);
  const [sharpen, setSharpen] = useState(0);
  const canvasRef = useRef(null);
  const originalImageDataRef = useRef(null);
  const originalWidthRef = useRef(0);
  const originalHeightRef = useRef(0);

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const imageArray = Array.from(files);
    setImages(imageArray);

    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = URL.createObjectURL(imageArray[0]);
    img.onload = () => {
      originalWidthRef.current = img.width;
      originalHeightRef.current = img.height;
      canvas.width = 300;
      canvas.height = 400;
      ctx.drawImage(img, 0, 0, 300, 400);
      originalImageDataRef.current = ctx.getImageData(0, 0, canvas.width, canvas.height);
    };
  };

  const applyEffects = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const imageData = ctx.createImageData(canvas.width, canvas.height);
    imageData.data.set(originalImageDataRef.current.data);
    const data = imageData.data;

    // Apply brightness
    if (brightness !== 0) {
      for (let i = 0; i < data.length; i += 4) {
        data[i] += brightness;
        data[i + 1] += brightness;
        data[i + 2] += brightness;
      }
    }

    // Apply contrast
    if (contrast !== 100) {
      const factor = (259 * (contrast + 255)) / (255 * (259 - contrast));
      for (let i = 0; i < data.length; i += 4) {
        data[i] = factor * (data[i] - 128) + 128;
        data[i + 1] = factor * (data[i + 1] - 128) + 128;
        data[i + 2] = factor * (data[i + 2] - 128) + 128;
      }
    }

    // Apply saturation
    if (saturation !== 100) {
      const saturationFactor = saturation / 100;
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const gray = 0.3 * r + 0.59 * g + 0.11 * b;
        data[i] = gray + saturationFactor * (r - gray);
        data[i + 1] = gray + saturationFactor * (g - gray);
        data[i + 2] = gray + saturationFactor * (b - gray);
      }
    }

    // Apply noise
    if (noise !== 0) {
      for (let i = 0; i < data.length; i += 4) {
        const random = Math.floor(Math.random() * noise * 2) - noise;
        data[i] += random;
        data[i + 1] += random;
        data[i + 2] += random;
      }
    }

    // Apply grayscale
    if (grayscale !== 0) {
      for (let i = 0; i < data.length; i += 4) {
        const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
        data[i] = data[i + 1] = data[i + 2] = avg;
      }
    }

    // Apply sepia
    if (sepia) {
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        data[i] = (r * 0.393) + (g * 0.769) + (b * 0.189);
        data[i + 1] = (r * 0.349) + (g * 0.686) + (b * 0.168);
        data[i + 2] = (r * 0.272) + (g * 0.534) + (b * 0.131);
      }
    }

    // Apply invert
    if (invert) {
      for (let i = 0; i < data.length; i += 4) {
        data[i] = 255 - data[i];
        data[i + 1] = 255 - data[i + 1];
        data[i + 2] = 255 - data[i + 2];
      }
    }

    // Apply hue rotation
    if (hue !== 0) {
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const hsv = rgbToHsv(r, g, b);
        hsv[0] = (hsv[0] + hue) % 360;
        const rgb = hsvToRgb(hsv[0], hsv[1], hsv[2]);
        data[i] = rgb[0];
        data[i + 1] = rgb[1];
        data[i + 2] = rgb[2];
      }
    }

    // Apply vibrance
    if (vibrance !== 100) {
      const vibranceFactor = vibrance / 100;
      for (let i = 0; i < data.length; i += 4) {
        const r = data[i];
        const g = data[i + 1];
        const b = data[i + 2];
        const max = Math.max(r, g, b);
        data[i] = r + (max - r) * vibranceFactor;
        data[i + 1] = g + (max - g) * vibranceFactor;
        data[i + 2] = b + (max - b) * vibranceFactor;
      }
    }

    // Apply sharpening (simple unsharp masking)
    if (sharpen !== 0) {
      const tempCanvas = document.createElement('canvas');
      const tempCtx = tempCanvas.getContext('2d');
      tempCanvas.width = canvas.width;
      tempCanvas.height = canvas.height;
      tempCtx.drawImage(canvas, 0, 0);
      tempCtx.filter = `blur(${sharpen}px)`;
      tempCtx.drawImage(tempCanvas, 0, 0);
      const blurredImageData = tempCtx.getImageData(0, 0, tempCanvas.width, tempCanvas.height);
      for (let i = 0; i < data.length; i += 4) {
        data[i] += data[i] - blurredImageData.data[i];
        data[i + 1] += data[i + 1] - blurredImageData.data[i + 1];
        data[i + 2] += data[i + 2] - blurredImageData.data[i + 2];
      }
    }

    ctx.putImageData(imageData, 0, 0);
  };

  useEffect(() => {
    if (originalImageDataRef.current) {
      applyEffects();
    }
  }, [brightness, contrast, saturation, noise, grayscale, sepia, invert, blur, hue, vibrance, sharpen]);

  const downloadImage = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // Create a temporary canvas
    const tempCanvas = document.createElement('canvas');
    const tempCtx = tempCanvas.getContext('2d');
    tempCanvas.width = originalWidthRef.current;
    tempCanvas.height = originalHeightRef.current;

    // Draw the image on the temporary canvas with original dimensions
    const img = new Image();
    img.src = canvas.toDataURL('image/png');
    img.onload = () => {
      tempCtx.drawImage(img, 0, 0, originalWidthRef.current, originalHeightRef.current);
      const dataUrl = tempCanvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'edited_image.png';
      link.href = dataUrl;
      link.click();
    };
  };

  const rgbToHsv = (r, g, b) => {
    r /= 255;
    g /= 255;
    b /= 255;
    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h, s, v = max;
    const d = max - min;
    s = max === 0 ? 0 : d / max;
    if (max === min) {
      h = 0;
    } else {
      switch (max) {
        case r: h = (g - b) / d + (g < b ? 6 : 0); break;
        case g: h = (b - r) / d + 2; break;
        case b: h = (r - g) / d + 4; break;
      }
      h /= 6;
    }
    return [h * 360, s * 100, v * 100];
  };

  const hsvToRgb = (h, s, v) => {
    h /= 360;
    s /= 100;
    v /= 100;
    let r, g, b;
    const i = Math.floor(h * 6);
    const f = h * 6 - i;
    const p = v * (1 - s);
    const q = v * (1 - f * s);
    const t = v * (1 - (1 - f) * s);
    switch (i % 6) {
      case 0: { r = v; g = t; b = p; break; }
      case 1: { r = q; g = v; b = p; break; }
      case 2: { r = p; g = v; b = t; break; }
      case 3: { r = p; g = q; b = v; break; }
      case 4: { r = t; g = p; b = v; break; }
      case 5: { r = v; g = p; b = q; break; }
    }
    return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
  };

  useEffect(() => {
    const mobileAdScript = document.createElement('script');
    mobileAdScript.type = 'text/javascript';
    mobileAdScript.innerHTML = `
      atOptions = {
        'key' : 'f8d39a20bda067dfaa7b4a8a5b54052e',
        'format' : 'iframe',
        'height' : 50,
        'width' : 320,
        'params' : {}
      };
    `;
    document.getElementById('mobile-ad').appendChild(mobileAdScript);

    const desktopAdScript = document.createElement('script');
    desktopAdScript.type = 'text/javascript';
    desktopAdScript.innerHTML = `
      atOptions = {
        'key' : '0e2f925c5683c087269043e090944d90',
        'format' : 'iframe',
        'height' : 600,
        'width' : 160,
        'params' : {}
      };
    `;
    document.getElementById('desktop-ad').appendChild(desktopAdScript);
  }, []);

  return (
    <div className="AI-image-editor-container">

    <div className="AI-image-editor-inner-container">
      <Helmet>
        <link rel="icon" href="../assets/icons/favicon.png" />
      </Helmet>

      <div className="image-editor-left-section">
          {/* Display ads */}
          <div id="mobile-ad"></div>
          <div id="desktop-ad"></div>
      </div>

      <div className="image-editor-middle-section">



        <div className="image-manipulation-tools">

          <div className="AI-creator-header">
            <h1>AI Image Editor</h1>
            <p>Edit your images with AI-powered tools!</p>
          </div>

          <input type="file" multiple onChange={handleImageUpload} id="image-editor-file-upload" />

          <div>
            <label>Brightness: {brightness}</label>
            <input type="range" min="-255" max="255" value={brightness} onChange={(e) => setBrightness(Number(e.target.value))} />
          </div>
          <div>
            <label>Contrast: {contrast}</label>
            <input type="range" min="-100" max="100" value={contrast} onChange={(e) => setContrast(Number(e.target.value))} />
          </div>
          <div>
            <label>Saturation: {saturation}</label>
            <input type="range" min="0" max="200" value={saturation} onChange={(e) => setSaturation(Number(e.target.value))} />
          </div>
          <div>
            <label>Noise: {noise}</label>
            <input type="range" min="0" max="100" value={noise} onChange={(e) => setNoise(Number(e.target.value))} />
          </div>
          <div>
            <label>Grayscale: {grayscale}</label>
            <input type="range" min="0" max="1" value={grayscale} onChange={(e) => setGrayscale(Number(e.target.value))} />
          </div>
          <div>
            <label>Sepia</label>
            <input type="checkbox" checked={sepia} onChange={(e) => setSepia(e.target.checked)} />
          </div>
          <div>
            <label>Invert</label>
            <input type="checkbox" checked={invert} onChange={(e) => setInvert(e.target.checked)} />
          </div>
          <div>
            <label>Blur: {blur}</label>
            <input type="range" min="0" max="10" value={blur} onChange={(e) => setBlur(Number(e.target.value))} />
          </div>
          <div>
            <label>Hue: {hue}</label>
            <input type="range" min="0" max="360" value={hue} onChange={(e) => setHue(Number(e.target.value))} />
          </div>
          <div>
            <label>Vibrance: {vibrance}</label>
            <input type="range" min="0" max="200" value={vibrance} onChange={(e) => setVibrance(Number(e.target.value))} />
          </div>
          <div>
            <label>Sharpen: {sharpen}</label>
            <input type="range" min="0" max="10" value={sharpen} onChange={(e) => setSharpen(Number(e.target.value))} />
          </div>

          <button onClick={downloadImage}>Download Image</button>
        </div>

        <div className="image-preview">
          <canvas ref={canvasRef} id="canvas" width="300" height="400"></canvas>
        </div>
      </div>

      <div className="image-editor-right-section">
        {/* Display ads */}
        <GoogleAdComponent />
      </div>
    </div>
    </div>
  );
};

export default AIImageEditor;