import React, { useState, useEffect } from 'react';
import GoogleAdComponent from '../GoogleAdComponent';
import { Helmet } from 'react-helmet';

async function query(data) {
    const response = await fetch(
      "https://api-inference.huggingface.co/models/facebook/musicgen-small",
      {
        headers: {
          Authorization: "Bearer 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",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const result = await response.arrayBuffer();
    return result;
}

const AIMusicCreator = () => {
  const [prompt, setPrompt] = useState('');
  const [audioUrl, setAudioUrl] = useState('');

  const handleGenerate = async () => {
    const audioData = await query({ inputs: prompt });
    const blob = new Blob([audioData], { type: 'audio/wav' });
    const url = URL.createObjectURL(blob);
    setAudioUrl(url);
  };

  useEffect(() => {
    const mobileAdScript = document.createElement('script');
    mobileAdScript.type = 'text/javascript';
    mobileAdScript.innerHTML = `
      atOptions = {
        'key' : 'f8d39a20bda067dfaa7b4a8a5b54052e',
        'format' : 'iframe',
        'height' : 50,
        'width' : 320,
        'params' : {}
      };
    `;
    document.getElementById('mobile-ad').appendChild(mobileAdScript);

    const desktopAdScript = document.createElement('script');
    desktopAdScript.type = 'text/javascript';
    desktopAdScript.innerHTML = `
      atOptions = {
        'key' : '0e2f925c5683c087269043e090944d90',
        'format' : 'iframe',
        'height' : 600,
        'width' : 160,
        'params' : {}
      };
    `;
    document.getElementById('desktop-ad').appendChild(desktopAdScript);
  }, []);

  return (
    <div className="music-creator-container">
      <Helmet>
        <link rel="icon" href="../assets/icons/favicon.png" />
      </Helmet>
      
      <h1>AI Music Creator</h1>
      <p>Make sounds with an AI-powered tool!</p>
      
<textarea 
  value={prompt} 
  onChange={(e) => setPrompt(e.target.value)}
  placeholder="Enter your music prompt here" 
  id="music-creator-input"
/>
      <button onClick={handleGenerate}>Generate Sound</button>
      
      {audioUrl && (
        <div>
          <audio controls src={audioUrl}></audio>
          <a href={audioUrl} download="generated_sound.wav">Download Sound</a>
        </div>
      )}
      {/* Display ads */}
      <div id="mobile-ad"></div>
      <div id="desktop-ad"></div>
    </div>
  );
};

export default AIMusicCreator;