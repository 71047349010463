import React, { useState, useEffect, useRef } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import './styles.css';

const Polymath = () => {
  const [categories, setCategories] = useState('music');
  const [difficulty, setDifficulty] = useState('easy');
  const [limit, setLimit] = useState('1');
  const [countdownIntervalId, setCountdownIntervalId] = useState(null);
  const location = useLocation();
  const audioRef = useRef(null);

  useEffect(() => {
    // Load the stored values when the page loads
    if (localStorage.getItem('difficulty')) {
      setDifficulty(localStorage.getItem('difficulty'));
    }
    if (localStorage.getItem('categories')) {
      setCategories(localStorage.getItem('categories'));
    }
    if (localStorage.getItem('limit')) {
      setLimit(localStorage.getItem('limit'));
    }
  }, []);

  const handleCategoryChange = (e) => {
    setCategories(e.target.value);
    localStorage.setItem('categories', e.target.value);
  };

  const handleDifficultyChange = (e) => {
    setDifficulty(e.target.value);
    localStorage.setItem('difficulty', e.target.value);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    localStorage.setItem('limit', e.target.value);
  };

  const toggleMusic = () => {
    if (audioRef.current.paused) {
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  };

  const startGame = () => {
    getQuestions().then(questions => {
      displayQuestions(questions);
    }).catch(error => {
      console.error('Error fetching questions:', error);
    });

    document.getElementById('timer').innerText = '00:00';
    let countdownWarning = document.getElementById('countdownWarning');
    let timer = document.getElementById('timer');

    let countdown = 5;
    countdownWarning.innerHTML = 'Your game begins in<br><br>';
    timer.innerText = formatTime(countdown);

    let countdownInterval = setInterval(function() {
      countdown--;
      if (countdown > 0) {
        timer.innerText = formatTime(countdown);
      } else {
        countdownWarning.innerText = '';
        timer.innerText = '00:00';
        clearInterval(countdownInterval);
        startCountdownTimer();
      }
    }, 1000);
  };

  const formatTime = (seconds) => {
    let minutes = Math.floor(seconds / 60);
    seconds %= 60;
    return (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds);
  };

  const getQuestions = () => {
    return new Promise((resolve, reject) => {
      let params = {
        difficulty: document.getElementById('difficulty').value,
        categories: document.getElementById('categories').value,
        limit: document.getElementById('limit').value,
      };

      let queryString = Object.keys(params).map(key => key + '=' + encodeURIComponent(params[key])).join('&');

      fetch('https://the-trivia-api.com/api/questions?' + queryString)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          if (data && Array.isArray(data)) {
            resolve(data);
          } else {
            reject('Invalid data format');
          }
        })
        .catch(error => reject(error));
    });
  };

  const displayQuestions = (questions) => {
    let newQuestion = new Audio('https://firebasestorage.googleapis.com/v0/b/polymathquest00.appspot.com/o/music%2FnewQuestion.mp3?alt=media&token=823a823c-6ace-47b7-ab78-ac9dd7923ac0');
    let answerAudio = new Audio('https://firebasestorage.googleapis.com/v0/b/polymathquest00.appspot.com/o/music%2FanswerSelect.mp3?alt=media&token=cf71ec56-0673-4200-8024-1a4825731cd1');

    questions.forEach(question => {
      newQuestion.play();

      let questionDiv = document.createElement('div');
      questionDiv.innerHTML = `<p>${question.question}</p>`;

      let allPossibleAnswers = [...question.incorrectAnswers, question.correctAnswer];
      shuffleArray(allPossibleAnswers);

      let answersDiv = document.createElement('div');
      allPossibleAnswers.forEach(answer => {
        let answerButton = document.createElement('button');
        answerButton.textContent = answer;
        answerButton.addEventListener('click', function() {
          handleAnswerSubmission(question, answerButton.textContent, questionDiv, answerButton);
          handleAnswerFeedback(question, answerButton.textContent, questionDiv, answerButton);
          answerButton.style.backgroundColor = 'green';
          answerAudio.play();
        });

        answerButton.style.backgroundColor = 'blue';
        answerButton.style.color = 'white';
        answerButton.style.fontSize = '14px';
        answerButton.style.padding = '5px';
        answerButton.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';

        answersDiv.appendChild(answerButton);
      });

      questionDiv.appendChild(answersDiv);
      document.getElementById('textOutput').appendChild(questionDiv);

      questionDiv.style.backgroundColor = 'orange';
      questionDiv.style.paddingTop = '5px';
      questionDiv.style.paddingBottom = '5px';
      questionDiv.style.marginBottom = '10px';
      questionDiv.style.borderRadius = '10px';
      questionDiv.style.boxShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';
      questionDiv.style.textShadow = '2px 2px 4px rgba(0, 0, 0, 0.5)';
    });
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const startCountdownTimer = () => {
    let timer = document.getElementById('timer');
    let limit = parseInt(document.getElementById('limit').value);
    let countdownDuration = 10 * limit;
    timer.innerText = formatTime(countdownDuration);

    const countdownTimerInterval = setInterval(function() {
      countdownDuration--;
      if (countdownDuration > -1) {
        timer.innerText = formatTime(countdownDuration);
      } else {
        clearInterval(countdownTimerInterval);
        document.getElementById('textOutput').innerText = 'Your time has elapsed. Feedback is loading...';
      }
    }, 1000);

    setCountdownIntervalId(countdownTimerInterval);
  };

  const handleAnswerFeedback = (question, userAnswer, questionDiv) => {
    let isCorrect = userAnswer === question.correctAnswer;
    let feedbackMessage = isCorrect ? 'Your answer was: ' + userAnswer + '. Correct, well done' : `Incorrect. The correct answer was: ${question.correctAnswer}`;

    let limitElement = document.getElementById('limit');
    let limit = parseInt(limitElement.value, 10);

    if (isNaN(limit)) {
      console.error('Invalid limit value.');
      return;
    }

    setTimeout(function() {
      document.getElementById('textOutput').innerText = feedbackMessage;
    }, 10000 * limit + 2000);
  };

  const handleAnswerSubmission = (question, userAnswer, questionDiv, answerButton) => {
    // Handle the submission of the answer
    console.log('Answer submitted:', userAnswer);
  };

  const refreshGame = () => {
    document.getElementById('textOutput').innerHTML = ''; // Clear the text output
    document.getElementById('timer').innerText = '00:00'; // Reset the timer
    document.getElementById('countdownWarning').innerText = ''; // Clear the countdown warning

    if (countdownIntervalId) {
      clearInterval(countdownIntervalId);
      setCountdownIntervalId(null);
    }
  };

  return (
    <div className="polymath-game">
      <div className="polymath-navigation">
        <div className="heading">
          <Link to="/games/polymath">
            <h1>Polymath</h1>
          </Link>

          <br />
          <p>Unleash Your Inner Genius</p>
        </div>
        <div className="menus">
          <div className="hamnPlay">
            <div className="hamburger" id="hamburger">
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
            <div id="play">
              <audio id="myAudio" controls controlsList="nodownload" autoPlay loop ref={audioRef}>
                <source
                  src="https://firebasestorage.googleapis.com/v0/b/polymathquest00.appspot.com/o/music%2Fgaming.mp3?alt=media&token=4fe1deb0-2585-4dec-8610-44a9da7fcc50"
                  type="audio/mpeg"
                />
              </audio>
            </div>
          </div>
          <div className="navnBtn">
            <div id="polymath-menu">
                <Link to="/games">Home</Link>
                <Link to="/games/polymath">Game</Link>
                <Link to="help-polymath">Help</Link>
                <Link to="about-polymath">About</Link>
                
            </div>
            <div id="soundButtonDiv">
              <button id="soundButton" onClick={toggleMusic}>Music</button>
            </div>
          </div>
        </div>
      </div>

      {location.pathname <= '/games/polymath' && (
        <div className="gameContent">
          <div id="filters">
            <form>
              <label htmlFor="categories">Category</label>
              <select id="categories" value={categories} onChange={handleCategoryChange}>
                <option value="music">Music</option>
                <option value="sport_and_leisure">Sport and Leisure</option>
                <option value="film_and_tv">Film and TV</option>
                <option value="arts_and_literature">Arts and Literature</option>
                <option value="history">History</option>
                <option value="society_and_culture">Society and Culture</option>
                <option value="science">Science</option>
                <option value="geography">Geography</option>
                <option value="food_and_drink">Food and Drink</option>
                <option value="general_knowledge">General Knowledge</option>
              </select>
            </form>

            <form>
              <label htmlFor="difficulty">Difficulty</label>
              <select id="difficulty" value={difficulty} onChange={handleDifficultyChange}>
                <option value="easy" className="option">
                  Easy
                </option>
                <option value="medium">Medium</option>
                <option value="hard">Hard</option>
              </select>
            </form>

            <form>
              <label htmlFor="limit">Limit</label>
              <select id="limit" value={limit} onChange={handleLimitChange}>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
              </select>
            </form>
          </div>

          <div className="polymath-avatars">
            <div className="playerOneContainer">
              <div className="getQuestionsBtn">
                <button id="getQuestionsButtonP1" onClick={startGame} className="getQuestionsButton">
                  Start Game
                </button>
              </div>
            </div>

            <div className="countdownTimer">
              <div id="countdownWarning"></div>
              <div id="timer">00:00</div>
            </div>

            <div className="playerTwoContainer">
              <div className="getQuestionsBtn">
                <button id="getQuestionsButtonP2" onClick={refreshGame} className="getQuestionsButton">
                  Refresh
                </button>
              </div>
            </div>
          </div>

          <hr />

          <div className="game">
            <div className="textOutputContainer">
              <div id="textOutput" className="textOutput">
                <div id="dummyTextOutput"></div>
              </div>
            </div>
          </div>

          <button onClick={() => {}} id="myBtn" title="Go to top">
            Back to top
          </button>
        </div>
      )}
      <Outlet />
    </div>
  );
};

export default Polymath;
