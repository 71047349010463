import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const TicTacToe = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (userId) {
      const fetchTicTacToeStage = async () => {
        try {
          const response = await axios.get(`https://mjondol.co.za:4000/user-tic-tac-toe-stage/${userId}`);
          const { TicTacToeStage } = response.data;
          navigate(`/games/tic-tac-toe/stage${TicTacToeStage}`);
        } catch (error) {
          console.error('Error fetching TicTacToeStage:', error);
          navigate('/games/tic-tac-toe/stage1'); // Default to stage 1 if there's an error
        }
      };

      fetchTicTacToeStage();
    } else {
      navigate('/games/tic-tac-toe/stage1'); // Default to stage 1 if no user is logged in
    }
  }, [navigate]);

  return null; // This component doesn't render anything itself
};

export default TicTacToe;
