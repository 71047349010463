import React, { useEffect } from 'react';

const GoogleAdComponent = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7158312114880419';
    script.async = true;
    script.crossOrigin = 'anonymous';
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (window.adsbygoogle && window.adsbygoogle.loaded === false) {
      window.adsbygoogle.push({});
    }
  }, []);

  return (
    <div>
      <ins className="adsbygoogle"
           style={{ display: 'block' }}
           data-ad-client="ca-pub-7158312114880419"
           data-ad-slot="1234567890"
           data-ad-format="auto"></ins>
    </div>
  );
};

export default GoogleAdComponent;
