import React from 'react';
import { Helmet } from 'react-helmet';

const AIRemoveBackgroud = () => {
  return (
    <div>
      <Helmet>
        <link rel="icon" href="../assets/icons/favicon.png" />
      </Helmet>

        <div className="AI-creator-header">
          <h1>AI Background Remover</h1>
          <p>Remove image background in seconds!</p>
        </div>
    </div>
  );
};

export default AIRemoveBackgroud;
