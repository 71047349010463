import React, { useState, useEffect } from 'react';

const BlogAdminNavigation = ({ onLogin }) => {
  const [user, setUser] = useState(null);
  const [username, setUsername] = useState('');

  return (
    <div className="blog-admin-header">
      <h2>SoT Blog Admin</h2>
    </div>
  );
};

export default BlogAdminNavigation;

