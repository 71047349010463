import React, { useEffect } from 'react';

const aiChatImage = require('../assets/images/aiChatBot.jpeg');
const aiImageCreator = require('../assets/images/aiImageCreator.jpeg');
const aiImageEditor = require('../assets/images/aiImageEditor.jpeg');
const customAppImage = require('../assets/images/customAppImage.jpeg');

const Shortcuts = () => {
  useEffect(() => {
    const boxes = document.querySelectorAll('.shortcutBox');
    boxes.forEach((box, index) => {
      setTimeout(() => {
        box.classList.add('fade-in');
      }, index * 500); // Stagger the fade-in effect
    });
  }, []);

  return (
    <div className="topShortcuts">

      <div id="toolOne" className="shortcutBox">
        <img src={aiChatImage} alt="Your Image" className="shortcutBoxBackgroundImage" />
        <div className="shortcutBoxOverlayText">Qamata <br/>AI Chat</div>
      </div>

      <div id="toolTwo" className="shortcutBox">
        <img src={aiImageCreator} alt="Your Image" className="shortcutBoxBackgroundImage" />
        <div className="shortcutBoxOverlayText">AI <br/>Image Creator</div>
      </div>
      <div id="toolThree" className="shortcutBox">
        <img src={aiImageEditor} alt="Your Image" className="shortcutBoxBackgroundImage" />
        <div className="shortcutBoxOverlayText">AI <br/>Image Editor</div>
      </div>

      <div id="toolFour" className="shortcutBox">
        <img src={customAppImage} alt="Your Image" className="shortcutBoxBackgroundImage" />
        <div className="shortcutBoxOverlayText">Custom <br/>Website or App</div>
      </div>
    </div>
  );
};

export default Shortcuts;
