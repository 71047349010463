import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const logo = require('./logo.png');

const TicStage4 = () => {
  const [board, setBoard] = useState(Array(36).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isXNext) {
      const aiMove = getAIMove(board);
      if (aiMove !== null) {
        setTimeout(() => handleClick(aiMove), 1000); // Add a delay of 1 second
      }
    }
  }, [isXNext]);

  const handleClick = (index) => {
    if (board[index] || calculateWinner(board)) return;

    const newBoard = board.slice();
    newBoard[index] = isXNext ? 'X' : 'O';
    setBoard(newBoard);
    setIsXNext(!isXNext);
  };

  const getAIMove = (board) => {
    const randomMove = () => {
      const availableMoves = board.map((value, index) => (value === null ? index : null)).filter((value) => value !== null);
      return availableMoves[Math.floor(Math.random() * availableMoves.length)];
    };

    const calculatedMove = () => {
      const lines = [
        [0, 1, 2, 3, 4, 5],
        [6, 7, 8, 9, 10, 11],
        [12, 13, 14, 15, 16, 17],
        [18, 19, 20, 21, 22, 23],
        [24, 25, 26, 27, 28, 29],
        [30, 31, 32, 33, 34, 35],
        [0, 6, 12, 18, 24, 30],
        [1, 7, 13, 19, 25, 31],
        [2, 8, 14, 20, 26, 32],
        [3, 9, 15, 21, 27, 33],
        [4, 10, 16, 22, 28, 34],
        [5, 11, 17, 23, 29, 35],
        [0, 7, 14, 21, 28, 35],
        [5, 10, 15, 20, 25, 30],
      ];

      // Check if AI can win
      for (let i = 0; i < lines.length; i++) {
        const [a, b, c, d, e, f] = lines[i];
        if (board[a] === 'O' && board[b] === 'O' && board[c] === 'O' && board[d] === 'O' && board[e] === 'O' && !board[f]) return f;
        if (board[a] === 'O' && board[b] === 'O' && board[c] === 'O' && board[d] === 'O' && board[f] === 'O' && !board[e]) return e;
        if (board[a] === 'O' && board[b] === 'O' && board[c] === 'O' && board[e] === 'O' && board[f] === 'O' && !board[d]) return d;
        if (board[a] === 'O' && board[b] === 'O' && board[d] === 'O' && board[e] === 'O' && board[f] === 'O' && !board[c]) return c;
        if (board[a] === 'O' && board[c] === 'O' && board[d] === 'O' && board[e] === 'O' && board[f] === 'O' && !board[b]) return b;
        if (board[b] === 'O' && board[c] === 'O' && board[d] === 'O' && board[e] === 'O' && board[f] === 'O' && !board[a]) return a;
      }

      // Block opponent's winning move
      for (let i = 0; i < lines.length; i++) {
        const [a, b, c, d, e, f] = lines[i];
        if (board[a] === 'X' && board[b] === 'X' && board[c] === 'X' && board[d] === 'X' && board[e] === 'X' && !board[f]) return f;
        if (board[a] === 'X' && board[b] === 'X' && board[c] === 'X' && board[d] === 'X' && board[f] === 'X' && !board[e]) return e;
        if (board[a] === 'X' && board[b] === 'X' && board[c] === 'X' && board[e] === 'X' && board[f] === 'X' && !board[d]) return d;
        if (board[a] === 'X' && board[b] === 'X' && board[d] === 'X' && board[e] === 'X' && board[f] === 'X' && !board[c]) return c;
        if (board[a] === 'X' && board[c] === 'X' && board[d] === 'X' && board[e] === 'X' && board[f] === 'X' && !board[b]) return b;
        if (board[b] === 'X' && board[c] === 'X' && board[d] === 'X' && board[e] === 'X' && board[f] === 'X' && !board[a]) return a;
      }

      // Take the center if available
      if (!board[17]) return 17;

      // Take any available spot
      for (let i = 0; i < board.length; i++) {
        if (board[i] === null) {
          return i;
        }
      }
      return null;
    };

    const randomThreshold = Math.random();
    if (randomThreshold < 0.1) {
      return randomMove();
    } else {
      return calculatedMove();
    }
  };

  const calculateWinner = (board) => {
    const lines = [
      [0, 1, 2, 3, 4, 5],
      [6, 7, 8, 9, 10, 11],
      [12, 13, 14, 15, 16, 17],
      [18, 19, 20, 21, 22, 23],
      [24, 25, 26, 27, 28, 29],
      [30, 31, 32, 33, 34, 35],
      [0, 6, 12, 18, 24, 30],
      [1, 7, 13, 19, 25, 31],
      [2, 8, 14, 20, 26, 32],
      [3, 9, 15, 21, 27, 33],
      [4, 10, 16, 22, 28, 34],
      [5, 11, 17, 23, 29, 35],
      [0, 7, 14, 21, 28, 35],
      [5, 10, 15, 20, 25, 30],
    ];

    for (let i = 0; i < lines.length; i++) {
      const [a, b, c, d, e, f] = lines[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c] && board[a] === board[d] && board[a] === board[e] && board[a] === board[f]) {
        return board[a];
      }
    }
    return null;
  };

  const winner = calculateWinner(board);
  const status = winner ? `Winner: ${winner}` : `Next player: ${isXNext ? 'X' : 'O'}`;

  useEffect(() => {
    if (winner === 'X') {
      const userId = localStorage.getItem('userId');
      if (userId) {
        axios.post('https://mjondol.co.za:4000/updateStage', { userId, stage: 4 })
          .then(response => {
            console.log(response.data);
            setTimeout(() => {
              navigate('/games/tic-tac-toe/stage5');
            }, 2000); // Add a delay before moving to the next stage
          })
          .catch(error => {
            console.error('Error updating stage:', error);
          });
      } else {
        navigate('/games/tic-tac-toe/stage5'); // Bypass DB logic and proceed to next stage if user ID is null
      }
    }
  }, [winner, navigate]);

  const handleReset = () => {
    setBoard(Array(36).fill(null));
    setIsXNext(true);
  };

  return (
    <div className="tic-tac-toe">

      {/* Add a header */}
      <div className="tic-tac-toe-navigation">
        <div id="tic-tac-toe-exit">
          <p><Link to="/games">Home</Link></p>
        </div>

        <div id="tic-tac-toe-logo-container">
          <img src={logo} id="tic-tac-toe-logo" alt="Logo" />
        </div>

        <div id="navigation-space-filler">
        </div>
      </div>

      {/* Add body of game */}
      <div className="tic-tac-toe-center">

        {/* Display stages of the game */}
        <div className="tic-tac-toe-stages-display">
          <p id="tic-tac-toe-stage-label">Stage</p>
          <Link to="/games/tic-tac-toe/stage1"><p id="tic-tac-toe-stage-won">One</p></Link>
          <Link to="/games/tic-tac-toe/stage2"><p id="tic-tac-toe-stage-won">Two</p></Link>
          <Link to="/games/tic-tac-toe/stage3"><p id="tic-tac-toe-stage-won">Three</p></Link>
          <div id="tic-tac-toe-stage-active">Four</div>
          <p id="tic-tac-toe-stage-inactive">Five</p>
        </div>

        <div className="status">{status}</div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(6, 50px)', gridTemplateRows: 'repeat(6, 50px)', gap: '5px' }}>
          {board.map((value, index) => (
            <button key={index} className="square" onClick={() => handleClick(index)} style={{ width: '100%', height: '100%', fontSize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {value}
            </button>
          ))}
        </div>
        <button className="reset-button" onClick={handleReset} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Reset</button>
      </div>
    </div>
  );
};

export default TicStage4;
