import React from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const logoTag = require('../assets/icons/logoTag.png');
const pioneerImage = require('../assets/images/pioneer.jpeg');
const yearImage = require('../assets/images/year.jpeg');
const innovatorImage = require('../assets/images/innovator.jpeg');

const AboutUs = () => {
  return (
    <div className="about-body-content">
      <img src={logoTag} id="about-us-logo" alt="logo"/>
      <div className="about-body-content-container">
        
        <hr />

        <div className="about-us-intro">
          <div id="intro-image">
            <img src={pioneerImage} alt="pioneer"/>
          </div>

          <div id="intro-paragraph">
            <p><span>Mjondol Labs</span> is a pioneering technology company dedicated to pushing the boundaries of innovation. Our mission is to create groundbreaking solutions that empower individuals and businesses to thrive in the digital age.
            </p>
          </div>
        </div>

        <hr />

        <div className="about-us-foundations">
          <div id="foundations-paragraph">
            <p>Founded in 2024, Mjondol Labs is driven by a passionate technologist and anchored by like-minded individuals who are committed to developing cutting-edge products and services. Our expertise spans across a wide range of fields, including artificial intelligence, machine learning, software and web development.</p>
          </div>

          <div id="foundations-image">
            <img src={yearImage} alt="2024"/>
          </div>
        </div>

        <hr />

        <div className="about-us-declaration">
          <div id="declaration-image">
            <img src={innovatorImage} alt="innovator"/>
          </div>

          <div id="declaration-paragraph">
            <p>At Mjondol Labs, we believe in the power of technology to transform lives. We are committed to creating solutions that are not only innovative but also user-friendly, accessible, and impactful. Our focus is on developing products that address real-world challenges and provide tangible benefits to our customers.</p>
          </div>
        </div>

        <hr />

          <h3>Vision:</h3>
          <p>To be a global leader in innovative technology solutions that empower individuals and businesses to achieve their full potential.</p>

          <h3>Values:</h3>
          <ul>
            <li><span>Innovation:</span> We are committed to pushing the boundaries of technology and creating groundbreaking solutions.</li>
            <li><span>Excellence:</span> We strive for the highest standards of quality in everything we do.</li>
            <li><span>Collaboration:</span> We believe in the power of teamwork and partnerships to achieve our goals.</li>
            <li><span>Customer Focus:</span> We are dedicated to understanding our customers' needs and delivering exceptional value.</li>
          </ul>

          <h3>Goals:</h3>
          <ul>
            <li>Develop cutting-edge products and services that address real-world challenges.</li>
            <li>Foster a culture of innovation and creativity within our organization.</li>
            <li>Build strong partnerships with clients, investors, and industry leaders.</li>
            <li>Make a positive impact on society through our technology.</li>
          </ul>

          <p>Join us on our journey of innovation and discovery. Explore our products and services, connect with us on social media. Together, we can shape the future of technology.</p>

          <div className="social-media-icons">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
            <a href="https://www.linkedin.com/company/105075824/" target="_blank"><FaLinkedin /></a>

            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
          </div>
      </div>
    </div>
  );
};

export default AboutUs;
