import React, { useEffect } from 'react';
import BlogNavigation from './BlogNavigation';

const BlogContact = () => {
  useEffect(() => {
    if (!document.querySelector('script[src="https://www.cognitoforms.com/f/seamless.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://www.cognitoforms.com/f/seamless.js';
      script.setAttribute('data-key', 'fNoK9VRiKUuLHzc05BzfEg');
      script.setAttribute('data-form', '1');
      script.async = true;
      document.getElementById('blog-contact-form-container').appendChild(script);
    }
  }, []);

  return (
    <div className="blog-contact-body">
      <BlogNavigation />

      <div className="blog-contact-body-container">
        <p>
          <span>Have a question or need assistance?</span> Feel free to contact us using the form below or reach out to us directly. We're here to help!
        </p>

        <div id="blog-contact-form-container" className="contact-container">
          {/* The form will be embedded here */}
        </div>
      </div>
    </div>
  );
}

export default BlogContact;
