import React, { useState, useEffect } from 'react';
import GoogleAdComponent from '../GoogleAdComponent';
import { Helmet } from 'react-helmet';

const AIImageCreator = () => {
  const [imageUrl, setImageUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [height, setHeight] = useState(1024);
  const [width, setWidth] = useState(1024);
  const [guidanceScale, setGuidanceScale] = useState(3.5);
  const [numInferenceSteps, setNumInferenceSteps] = useState(50);

  async function query(data) {
    const response = await fetch(
      "https://api-inference.huggingface.co/models/black-forest-labs/FLUX.1-dev",
      {
        headers: {
          Authorization: "Bearer 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",
          "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(data),
      }
    );
    const result = await response.blob();
    return result;
  }

  const createImage = async () => {
    setLoading(true);
    const requestData = {
      inputs: prompt,
      height: height,
      width: width,
      guidance_scale: guidanceScale,
      num_inference_steps: numInferenceSteps,
      max_sequence_length: 512,
    };
    console.log('Request Data:', requestData); // Log the request data
    try {
      const response = await query(requestData);
      console.log('API Response:', response); // Log the API response
      const imageUrl = URL.createObjectURL(response);
      setImageUrl(imageUrl);
    } catch (error) {
      console.error('Error creating image:', error);
    } finally {
      setLoading(false);
    }
  };

  const downloadImage = () => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'ai-created-image.jpeg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const mobileAdScript = document.createElement('script');
    mobileAdScript.type = 'text/javascript';
    mobileAdScript.innerHTML = `
      atOptions = {
        'key' : 'f8d39a20bda067dfaa7b4a8a5b54052e',
        'format' : 'iframe',
        'height' : 50,
        'width' : 320,
        'params' : {}
      };
    `;
    document.getElementById('mobile-ad').appendChild(mobileAdScript);

    const desktopAdScript = document.createElement('script');
    desktopAdScript.type = 'text/javascript';
    desktopAdScript.innerHTML = `
      atOptions = {
        'key' : '0e2f925c5683c087269043e090944d90',
        'format' : 'iframe',
        'height' : 600,
        'width' : 160,
        'params' : {}
      };
    `;
    document.getElementById('desktop-ad').appendChild(desktopAdScript);
  }, []);

  return (
    <div className="main">
      <Helmet>
        <link rel="icon" href="../assets/icons/favicon.png" />
      </Helmet>

      <div className="main-container">
        <div className="left-section">
          {/* Display ads */}
          <div id="mobile-ad"></div>
          <div id="desktop-ad"></div>
        </div>

        <div className="middle-section">
          <div className="ai-image-creator-container">
            <div className="parameters">
              <h1 id="image-creator-label">AI Image Creator</h1>
              <p>Create stunning images with AI!</p>

              <textarea
                type="text"
                className="image-creator-prompt-input"
                placeholder="  Enter your prompt here and press create below."
                value={prompt}
                onChange={(e) => setPrompt(e.target.value)}
              />
              <label>Size:</label>
              <div className="button-group">
                <button className={height === 1024 && width === 1024 ? 'selected' : ''} onClick={() => { setHeight(1024); setWidth(1024); }}>Square</button>
                <button className={height === 1024 && width === 768 ? 'selected' : ''} onClick={() => { setHeight(1024); setWidth(768); }}>Portrait</button>
                <button className={height === 768 && width === 1024 ? 'selected' : ''} onClick={() => { setHeight(768); setWidth(1024); }}>Landscape</button>
              </div>
              <label>Height:</label>
              <input
                type="number"
                placeholder="Enter height"
                value={height}
                onChange={(e) => setHeight(parseInt(e.target.value))}
              />
              <label>Width:</label>
              <input
                type="number"
                placeholder="Enter width"
                value={width}
                onChange={(e) => setWidth(parseInt(e.target.value))}
              />
              <label>Guidance Scale:</label>
              <div className="slider-container">
                <input
                  type="range"
                  min="1"
                  max="20"
                  step="0.1"
                  value={guidanceScale}
                  onChange={(e) => setGuidanceScale(parseFloat(e.target.value))}
                />
                <span>{guidanceScale}</span>
              </div>
              <label>Number of Inference Steps:</label>
              <div className="slider-container">
                <input
                  type="range"
                  min="1"
                  max="100"
                  value={numInferenceSteps}
                  onChange={(e) => setNumInferenceSteps(parseInt(e.target.value))}
                />
                <span>{numInferenceSteps}</span>
              </div>
              <button className="create-image-button" onClick={createImage} disabled={loading}>
                {loading ? 'Creating...' : 'Create Image'}
              </button>
              <button className="created-image-download-button" onClick={downloadImage}>Download Image</button>
            </div>

            <div className="image-creator-preview-container">
              <div className="image-creator-preview">
                {imageUrl && (
                  <>
                    <img src={imageUrl} height="auto" width="100%" alt="AI Created" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="right-section">
          {/* Display ads */}
          <GoogleAdComponent />
        </div>
      </div>
    </div>
  );
};

export default AIImageCreator;
