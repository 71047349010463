import React from 'react';
import { Link } from 'react-router-dom';
import Shortcuts from './Shortcuts'; 

const chatbot = require('../assets/images/chatbot.png');
const appImage = require('../assets/images/appImage.jpeg');
const aiImageCreator = require('../assets/images/aiImageCreator.jpeg');
const aiImageEditor = require('../assets/images/aiImageEditor.jpeg');
const webDesign = require('../assets/images/webDesign.jpeg');
const ticTacToe = require('../assets/images/ticTacToe.jpeg');
const trivia = require('../assets/images/trivia.jpeg');

const Home = () => {
  return (
    <div className="home-body-content-container">
      <div className="home-body-content">
        
        <div className="qamata-showcase-card-container">
          <div className="qamata-showcase-card">
            <div className="qamata-showcase-card-image">
              <img src={chatbot} alt="ai chat" />
            </div>

            <div className="qamata-showcase-card-text">
              <h1><span>Chat with</span> Qamata</h1>
              <h4>Your AI assistant for coding, planning, research and more.</h4>
              <p>Intelligent, adaptable, and always ready to help.</p>
              <button><Link to="/tools/ai-chat">Start chat</Link></button>
            </div>
          </div>
        </div>

        <div className="linear-card-series">
          <div className="linear-card-content">
            <div id="linear-card-image">
              <img src={appImage} alt="app image" />
            </div>

            <div id="linear-card-text">
              <h2>Custom Apps</h2>
              <h4>Reach larger audiences</h4>
              <p>Investing in an app is a must in this digital age.<br/><br/> Our team of experts is on standby to help you with that.</p>
              <button><Link to="/contact-us">Order now</Link></button>
            </div>
          </div>

          <div className="linear-card-content">
            <div id="linear-card-image">
              <img src={aiImageCreator} alt="ai image editor" />
            </div>

            <div id="linear-card-text">
              <h2>AI Image Creator</h2>
              <h4>Create top quality images for your content</h4>
              <p>All you have to do is type in how you want it to look</p>
              <button><Link to="/tools/ai-image-creator">Start creating</Link></button>
            </div>
          </div>

          <div className="linear-card-content">
            <div id="linear-card-image">
              <img src={aiImageEditor} alt="web design" />
            </div>

            <div id="linear-card-text">
              <h2>AI Image Editor</h2>
              <h4>Choose from a range of effects</h4>
              <p>Spruce up your pictures to make them look perfect</p>
              <button><Link to="/tools/ai-image-editor">Start editing</Link></button>
            </div>
          </div>

          <div className="linear-card-content">
            <div id="linear-card-image">
              <img src={webDesign} alt="web design" />
            </div>

            <div id="linear-card-text">
              <h2>Custom Website</h2>
              <h4>You need a virtual office</h4>
              <p>An amazing website sells and communicates with your potential clients around the clock.<br /><br/> Let us make you stand out.</p>
              <button><Link to="/contact-us">Order now</Link></button>
            </div>
          </div>
        </div>

        <div className="confidence-boost">
        <hr/>
          <p>We are transforming technology to empower individuals and businesses. From AI to gaming, machine learning, website and software development. Our innovative solutions are user-friendly, accessible, and impactful.
          </p>
        <hr/>
        </div>

        <h1 id="games-showcase-cards-title">
          Games
        </h1>

        <div className="games-showcase-cards">
          <div className="games-showcase-card-content">
            <div id="games-showcase-card-image">
              <img src={ticTacToe} alt="tic tac toe" />
            </div>
            <div className="games-showcase-card-text">
                <button><h3><Link to="/games/tic-tac-toe/">Play</Link></h3></button>
                <h1>Tic Tac Toe</h1>
                <p>A classic game where players take turns marking spaces in a grid. The goal is to be the first to get all of your marks in a row, either horizontally, vertically, or diagonally. It’s a simple yet strategic game that can be enjoyed by people of all ages!</p>
            </div>
          </div>

          <div className="games-showcase-card-content">
            <div id="games-showcase-card-image">
              <img src={trivia} alt="trivia" />
            </div>
            <div className="games-showcase-card-text">
                <button><h3><Link to="/games/polymath">Play</Link></h3></button>
                <h1>Polymath</h1>
                <p>A trivia game designed to challenge your knowledge across a wide range of disciplines. Players answer questions from various fields. The goal is to test and expand your understanding, making it a fun and educational experience for those who enjoy learning about different subjects</p>

            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Home;
