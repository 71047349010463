import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const EditPost = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const post = location.state?.post || {};
  const [title, setTitle] = useState(post.Title || '');
  const [content, setContent] = useState(post.Content || '');
  const [author, setAuthor] = useState(post.Author || '');
  const [media, setMedia] = useState(post.media || '');
  const [editedBy, setEditedBy] = useState('');

  useEffect(() => {
    if (!post.ArticleId) {
      navigate('/blog');
    }
  }, [post, navigate]);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (!title || !content || !author || !editedBy) {
      alert('Title, Content, Author, and EditedBy cannot be empty');
      return;
    }
    try {
      console.log('Updating post with data:', { title, content, author, media, editedBy });
      await axios.put(`https://mjondol.co.za:4000/posts/${post.ArticleId}`, {
        Title: title,
        Content: content,
        Author: author,
        media,
        EditedBy: editedBy
      });
      navigate('/blog');
    } catch (error) {
      console.error('Error updating post:', error);
    }
  };

  // Custom Upload Adapter for Media Upload
  class MyUploadAdapter {
    constructor(loader, onUploadComplete) {
      this.loader = loader;
      this.onUploadComplete = onUploadComplete;
    }

    upload() {
      return this.loader.file.then(
        (file) =>
          new Promise((resolve, reject) => {
            const formData = new FormData();
            formData.append('file', file);
            axios
              .post('https://mjondol.co.za:4000/upload', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
              })
              .then((response) => {
                const url = response.data.url;
                this.onUploadComplete(url);
                resolve({
                  default: url,
                });
              })
              .catch((error) => {
                reject(error);
              });
          })
      );
    }

    abort() {
      // Handle abort if necessary
    }
  }

  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader, (url) => setMedia(url));
    };
  }

  return (
    <div className="edit-window">
      {post ? (
        <form onSubmit={handleUpdate}>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Title"
          />
          <input
            type="text"
            value={author}
            onChange={(e) => setAuthor(e.target.value)}
            placeholder="Author"
          />
          <input
            type="text"
            value={editedBy}
            onChange={(e) => setEditedBy(e.target.value)}
            placeholder="Edited By"
          />
          <CKEditor
            editor={ClassicEditor}
            data={content}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data);
            }}
            config={{
              extraPlugins: [MyCustomUploadAdapterPlugin],
              image: {
                toolbar: [
                  'imageTextAlternative',
                  'imageStyle:full',
                  'imageStyle:side',
                  'resizeImage:50',
                  'resizeImage:75',
                  'resizeImage:original',
                  'cropImage'
                ]
              }
            }}
          />
          <button type="submit">Update</button>
        </form>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default EditPost;
