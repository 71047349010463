import React from 'react';
import { Link, Outlet, useLocation, NavLink } from 'react-router-dom';

const BlogNavigation = ({ user, isAdmin }) => {
  const location = useLocation();
  return (
    <div>
      {/* Add navigation and blog title */}
      <div className="blog-navigation">
        <div id="blog-content-title">
          <Link to="/blog"><h1>State of Technology</h1></Link>
        </div>

        <div id="blog-navigation-links">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/blog">Blog</NavLink>
          <NavLink to="/about-blog">About blog</NavLink>
          <NavLink to="/blog-contact">Contact Us</NavLink>
          {isAdmin && (
            <NavLink to="/blog-admin">Admin</NavLink>
          )}
        </div>
      </div>
    </div>
  );
};

export default BlogNavigation;
