import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

const AISlideCreator = () => {
  const [images, setImages] = useState([]);
  const [boxes, setBoxes] = useState([{ id: 1, isPlus: true, image: null, effect: null, duration: 3 }]);
  const [activeTab, setActiveTab] = useState('images');
  const [selectedBox, setSelectedBox] = useState(null);
  const [selectedEffect, setSelectedEffect] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [currentBoxIndex, setCurrentBoxIndex] = useState(0);

  const handleImageUpload = (event) => {
    const files = event.target.files;
    const newImages = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const reader = new FileReader();
      reader.onload = (e) => {
        newImages.push(e.target.result);
        setImages([...images, ...newImages]);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAddBox = (index) => {
    const newBoxes = [...boxes];
    newBoxes.splice(index, 0, { id: Date.now(), isPlus: false, image: null, effect: null, duration: 3 });
    setBoxes(newBoxes);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleDragStart = (event, image) => {
    event.dataTransfer.setData('text/plain', image);
  };

  const handleDrop = (event, boxId) => {
    event.preventDefault();
    const image = event.dataTransfer.getData('text/plain');
    const newBoxes = boxes.map(box =>
      box.id === boxId ? { ...box, image } : box
    );
    setBoxes(newBoxes);
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleBoxClick = (boxId) => {
    setSelectedBox(boxId);
  };

  const handleEffectClick = (effect) => {
    setSelectedEffect(effect);
    const newBoxes = boxes.map(box => {
      if (box.id === selectedBox) {
        if (box.effect === effect) {
          return { ...box, effect: null };
        }
        return { ...box, effect };
      }
      return box;
    });
    setBoxes(newBoxes);
  };

  const handleDurationChange = (event) => {
    const duration = parseFloat(event.target.value);
    const newBoxes = boxes.map(box =>
      box.id === selectedBox ? { ...box, duration } : box
    );
    setBoxes(newBoxes);
  };

  const handlePlayClick = () => {
    setPlaying(true);
    setCurrentBoxIndex(0);
  };

  useEffect(() => {
    if (playing && currentBoxIndex < boxes.length) {
      const timeout = setTimeout(() => {
        setCurrentBoxIndex(currentBoxIndex + 1);
      }, boxes[currentBoxIndex].duration * 1000);

      return () => clearTimeout(timeout);
    } else {
      setPlaying(false);
    }
  }, [playing, currentBoxIndex, boxes]);

  const effects = [
    'Fade', 'rotate', 'scale', 'grayscale', 'blur',
    'flyIn', 'zoom', 'wipe',
    'pulse', 'spin', 'grow/shrink', 'colorPulse',
    'fadeOut', 'flyOut', 'zoomOut', 'wipeOut',
    'tumble', 'neon', 'scrapbook', 'drift', 'tectonic', 'pop', 'rise', 'pan', 'stomp', 'rollIn', 'heartbeat'
  ];

  const effectDisplayNames = {
    'Fade': 'Fade In',
    'rotate': 'Rotate',
    'scale': 'Scale Up',
    'grayscale': 'Grayscale',
    'blur': 'Blur',
    'flyIn': 'Fly In',
    'zoom': 'Zoom In',
    'wipe': 'Wipe In',
    'pulse': 'Pulse',
    'spin': 'Spin',
    'grow/shrink': 'Grow/Shrink',
    'colorPulse': 'Color Pulse',
    'fadeOut': 'Fade Out',
    'flyOut': 'Fly Out',
    'zoomOut': 'Zoom Out',
    'wipeOut': 'Wipe Out',
    'tumble': 'Tumble',
    'neon': 'Neon',
    'scrapbook': 'Scrapbook',
    'drift': 'Drift',
    'tectonic': 'Tectonic',
    'pop': 'Pop',
    'rise': 'Rise',
    'pan': 'Pan',
    'stomp': 'Stomp',
    'rollIn': 'Roll In',
    'heartbeat': 'Heartbeat'
  };

  return (
    <div>
      <Helmet>
        <link rel="icon" href="../assets/icons/favicon.png" />
      </Helmet>

      <div className="AI-creator-container">
        <div className="left-section">
          <div className="tabs">
            <button className={activeTab === 'images' ? 'active' : ''} onClick={() => handleTabClick('images')}>Images</button>
            <button className={activeTab === 'effects' ? 'active' : ''} onClick={() => handleTabClick('effects')}>Effects</button>
          </div>
          <div className="content">
            {activeTab === 'images' && (
              <div className="image-upload-section">
                <input type="file" accept="image/*" multiple onChange={handleImageUpload} />
                <div className="image-preview">
                  {images.map((image, index) => (
                    <img
                      key={index}
                      src={image}
                      alt={`Preview ${index + 1}`}
                      style={{ width: '200px', height: '200px', margin: '10px' }}
                      draggable
                      onDragStart={(event) => handleDragStart(event, image)}
                    />
                  ))}
                </div>
              </div>
            )}

            {activeTab === 'effects' && (
              <div className="effects-section">
                <p>Select an effect to apply to the selected box:</p>
                <div className="effects-list">
                  {effects.map((effect, index) => (
                    <button
                      key={index}
                      onClick={() => handleEffectClick(effect)}
                    >
                      {effectDisplayNames[effect]}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="display-section">
          <div className="display-container" style={{ width: '800px', height: '400px', border: '1px solid #ccc' }}>
            {playing && currentBoxIndex < boxes.length ? (
              <div className="display-content">
                {boxes[currentBoxIndex]?.image && (
                  <img
                    src={boxes[currentBoxIndex]?.image}
                    alt="Selected"
                    style={{ width: '50%', height: '60%', transition: 'transform 3s, filter 3s' }}
                    className={boxes[currentBoxIndex]?.effect}
                  />
                )}
              </div>
            ) : (
              selectedBox && (
                <div className="display-content">
                  {boxes.find(box => box.id === selectedBox)?.image && (
                    <img
                      src={boxes.find(box => box.id === selectedBox)?.image}
                      alt="Selected"
                      style={{ width: '50%', height: '60%', transition: 'transform 3s, filter 3s' }}
                      className={boxes.find(box => box.id === selectedBox)?.effect}
                    />
                  )}
                </div>
              )
            )}
          </div>
          <div className="effect-duration-container">
            <span>Effect: {selectedBox && effectDisplayNames[boxes.find(box => box.id === selectedBox)?.effect]}</span>
            <input
              type="number"
              value={selectedBox ? boxes.find(box => box.id === selectedBox)?.duration : 3}
              onChange={handleDurationChange}
              step="0.1"
              min="0"
            />
          </div>
          <div className="scrollable-section-container">
            <div className="scrollable-section">
              {boxes.map((box, index) => (
                <div
                  key={box.id}
                  className={`box ${box.isPlus ? 'plus-box' : ''}`}
                  onClick={() => box.isPlus ? handleAddBox(index) : handleBoxClick(box.id)}
                  onDrop={(event) => !box.isPlus && handleDrop(event, box.id)}
                  onDragOver={handleDragOver}
                >
                  {box.image && (
                    <img
                      src={box.image}
                      alt="Box"
                      style={{ width: '100%', height: '100%', transition: 'transform 3s, filter 3s' }}
                      className={box.effect}
                    />
                  )}
                  {box.isPlus && <span className="plus-sign">+</span>}
                </div>
              ))}
            </div>
            <button className="play-button" onClick={handlePlayClick}>Play</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AISlideCreator;
