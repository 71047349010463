import React, { useState } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa'; // Import the hamburger icon

const logo = require('../assets/icons/logo.png');
const avatar = require('../assets/icons/avatar.png');

const Navigation = ({ handleOpenModal, user, handleLogout }) => {
  const [isOpen, setIsOpen] = useState(false); // State to handle menu open/close

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const serverDown = () => {
    alert("Sorry, authentication server is currently under maintenance.");
  }

  return (
    <div className="navigation">


      <div className="logo-and-login">


        <div id="logo">
          <Link to="/"><img src={logo} id="logoImage" alt="logo" /></Link>
        </div>

        <div id="vertical-line">
          {/* This is just a vertical line dividing logo and menu*/}
        </div>

        <div id="menu" className={isOpen ? 'open' : ''}>
          <NavLink to="/" onClick={toggleMenu}>Home</NavLink>
          <NavLink to="/tools" onClick={toggleMenu}>Products</NavLink>
          <NavLink to="/games" onClick={toggleMenu}>Games</NavLink>
          <NavLink to="/services" onClick={toggleMenu}>Services</NavLink>
          <NavLink to="/pricing" onClick={toggleMenu}>Pricing</NavLink>
          {/*<NavLink to="/blog" onClick={toggleMenu}>Blog</NavLink>*/}
          <NavLink to="/about-us" onClick={toggleMenu}>About Us</NavLink>
          <NavLink to="/contact-us" onClick={toggleMenu}>Contact Us</NavLink>
        </div>

        </div>

        {/*<div id="login-avatar">
          <img src={avatar} alt="avatar" onClick={user ? handleLogout : handleOpenModal}/>
        </div>*/}

        {/* While server is still down */}
        <div id="login-avatar">
          <img src={avatar} alt="avatar" onClick={serverDown}/>
        </div>
      
      {/*<div className="loginButtonContainer">
        <button id="loginButton" onClick={user ? handleLogout : handleOpenModal}>
          {user ? `Welcome, ${user}` : ''}
          <img src={avatar} />
        </button>
      </div>*/}

        <div id="hamburger-icon" onClick={toggleMenu}>
          <FaBars />
        </div>

      <div className="loginButtonContainer">
        <button id="loginButton" onClick={serverDown}>
          {user ? `Welcome, ${user}` : ''}
          <img src={avatar} />
        </button>
      </div>
    </div>
  );
};

export default Navigation;
