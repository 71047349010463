import React from 'react';

const Pricing = () => {
  return (
    <div className="pricing-body-content">
      <div className="pricing-header">
        <h1>Pricing</h1>
        <p>We offer competitive pricing tailored to your specific project requirements. Our pricing structure is transparent, ensuring you get the best value for your investment. We normally quote clients based on their unique needs since every client has their own set of specifications and requirements. Below is a general overview of our pricing.</p>
      </div>

      <div className="pricing-dermacation">
        <div className="software-development-pricing">
          <h3>Software Development Pricing</h3>
          <ul>
            <li><span>Custom Software Solutions:</span> Starting at R49,000</li>
            <li><span>Mobile App Development:</span> Starting at R52,200 per platform (iOS or Android)</li>
            <li><span>Desktop Application Development:</span> Starting at R69,600</li>
            <li><span>Enterprise Software:</span> Custom pricing based on project scope</li>
          </ul>
        </div>

        <div className="web-development-pricing">
          <h3>Web Development Pricing</h3>
          <ul>
            <li><span>Website Design and Development:</span> Starting at R2,990</li>
            <li><span>E-commerce Development:</span> Starting at R7,000</li>
            <li><span>Web Application Development:</span> Starting at R15,200</li>
            <li><span>Web API Development:</span> Starting at R26,100</li>
          </ul>
        </div>
      </div>

      <div className="additional-services">
        <h2>Additional Services</h2>
        <p><span>Maintenance and Support:</span> <br /> Our plans accomodate a month-to-month basis as well as a service-when-needed arrangement. The pricing will depend on the extent of the needed support or maintenance.</p>

      </div>
<hr />
      <div id="pricing-clarity">
        <p>Please note that these prices are indicative and may vary based on the complexity and specific requirements of your project. For a detailed quote, feel free to contact us and we’ll be happy to discuss your needs and provide a customized pricing plan.</p>
      </div>
<hr />
    </div>
  );
};

export default Pricing;
