import React from 'react';

const Services = () => {
  return (
    <div className="services-body-content">
      <div className="services-heading">
        <h1>Our Services</h1>
      </div>

      <div className="services-dermacation">
        <div className="software-services">
          <h2>Software Development</h2>
          <h3>Custom Software Solutions:</h3>
            <p>We specialize in creating tailor-made software solutions that cater to your unique business needs. Our team ensures that the software we develop maximizes efficiency and productivity, providing you with a competitive edge in your industry.</p>

          <h3>Mobile App Development:</h3>
            <p>Our expertise in mobile app development allows us to create innovative and user-friendly mobile applications for both iOS and Android platforms. By leveraging the latest technologies, we help you reach a wider audience and enhance user engagement.</p>

          <h3>Desktop Application Development:</h3>
            <p>We design and develop robust desktop applications that are user-friendly and compatible with various operating systems. Our desktop solutions are built to handle complex tasks and provide a seamless user experience.</p>
            <h3>Enterprise Software:</h3>
          <p>Our enterprise software solutions are designed to be scalable and efficient, streamlining your business processes and improving overall productivity. We work closely with you to understand your requirements and deliver software that meets your organizational goals.</p>
        </div>

        <div className="web-services">
          <h2>Web Development</h2>
          <h3>Website Design and Development:</h3>
          <p>We craft visually appealing and responsive websites that leave a lasting impression on your visitors. Our design and development process ensures that your website is not only aesthetically pleasing but also functional and easy to navigate.</p>

          <h3>E-commerce Development:</h3>
          <p>Our e-commerce development services focus on building online stores that offer a seamless shopping experience. We integrate secure payment gateways and user-friendly interfaces to ensure that your customers have a smooth and enjoyable shopping journey.</p>

          <h3>Web Application Development:</h3>
          <p>We create dynamic web applications that enhance your business operations and provide a better user experience. Our web applications are designed to be scalable, secure, and efficient, helping you achieve your business objectives.</p>

            <h3>Web API Development:</h3>
          <p>Our web API development services enable you to integrate your web applications with other systems, enhancing their functionality and interoperability. We develop APIs that are secure, reliable, and easy to use, ensuring seamless communication between different software systems.</p>

        </div>
      </div>
    </div>
  );
};

export default Services;
