import React, { useState } from 'react';
import '../App.css';

const LoginForm = ({ handleClose, handleLogin }) => {
  const [isRegister, setIsRegister] = useState(false);
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
  });
  const [loggedIn, setLoggedIn] = useState(false);

  const toggleForm = () => {
    setIsRegister(!isRegister);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

const handleSubmit = async (e) => {
  e.preventDefault();
  const url = isRegister ? 'https://mjondol.co.za:4000/register' : 'https://mjondol.co.za:4000/login';
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    });
    const data = await response.json();
    if (response.ok) {
      if (isRegister) {
        localStorage.setItem('userId', data.userId);
        localStorage.setItem('username', data.username);
      }
      alert(isRegister ? 'User registered successfully!' : 'Login successful!');
      handleLogin(data.username, data.userId);
      setLoggedIn(true);
      handleClose();
    } else {
      alert(data.message || 'An error occurred');
    }
  } catch (error) {
    console.error('Error:', error);
    alert('An error occurred');
  }
};


  return (
    <div className="login-form">
      <h2>{isRegister ? 'Register' : 'Login'}</h2>
      <form onSubmit={handleSubmit}>
        {isRegister && (
          <div>
            <label>Username:</label>
            <input type="text" name="username" value={formData.username} onChange={handleChange} required />
          </div>
        )}
        <div>
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div>
          <label>Password:</label>
          <input type="password" name="password" value={formData.password} onChange={handleChange} required />
        </div>
        <button type="submit">{loggedIn ? 'Logged In' : (isRegister ? 'Register' : 'Login')}</button>
      </form>
      <button onClick={toggleForm}>
        {isRegister ? 'Already have an account? Login' : "Don't have an account? Register"}
      </button>
    </div>
  );
};

export default LoginForm;
