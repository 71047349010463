import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { HfInference } from "@huggingface/inference";
import ReactMarkdown from 'react-markdown';
import GoogleAdComponent from '../GoogleAdComponent';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const aiChatIcon = require('../../assets/icons/send.png');
const qamataLogo = require('../../assets/icons/qamata-logo.png');

const AIChat = () => {
  const [chatHistory, setChatHistory] = useState([]);
  const [userInput, setUserInput] = useState('');
  const textareaRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [displayedText, setDisplayedText] = useState('');
  const [showHistory, setShowHistory] = useState(false); 
  const [historyList, setHistoryList] = useState([]);
  const fullText = 'Hii, I am Qamata, your friendly, knowledgeable and dedicated AI assistant. Ask me anything.';
  const inference = new HfInference("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");

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  }, [userInput]);

  useEffect(() => {
    let index = 0;
    const interval = setInterval(() => {
      if (index < fullText.length) {
        setDisplayedText((prev) => prev + (fullText[index] || ''));
        index++;
      } else {
        clearInterval(interval);
      }
    }, 30); // Adjust the speed by changing the interval time (in milliseconds)
    return () => clearInterval(interval);
  }, []);

  const handleQuery = async () => {
    setLoading(true);
    setError(null);
    let responseContent = '';
    try {
      const messages = [
        ...chatHistory.map(message => ({ role: message.role === 'user' ? 'user' : 'assistant', content: message.content })),
        { role: "user", content: userInput }
      ];

      for await (const chunk of inference.chatCompletionStream({
        model: "mistralai/Mixtral-8x7B-Instruct-v0.1",
        messages: messages,
        max_tokens: 1000,
      })) {
        responseContent += chunk.choices[0]?.delta?.content || "";
      }

      console.log('API Response:', responseContent); // Log the entire response

      const newChatHistory = [
        ...chatHistory,
        { role: 'user', content: userInput },
        { role: 'assistant', content: responseContent || 'No response' },
      ];

      setChatHistory(newChatHistory);
      setUserInput('');

      // Extract heading from responseContent (you can customize this logic)
      const heading = responseContent.split('\n')[0]; // Example: taking the first line as heading

      // Save chat history with timestamp and heading
      const timestamp = new Date().toLocaleString();
      setHistoryList([...historyList, { timestamp, heading, chat: newChatHistory }]);
    } catch (err) {
      console.error(err);
      setError('Oops! Failed to fetch response. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      alert('Copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  };

  const loadHistory = (chat) => {
    setChatHistory(chat);
    setShowHistory(false);
  };

  useEffect(() => {
    const mobileAdScript = document.createElement('script');
    mobileAdScript.type = 'text/javascript';
    mobileAdScript.innerHTML = `
      atOptions = {
        'key' : 'f8d39a20bda067dfaa7b4a8a5b54052e',
        'format' : 'iframe',
        'height' : 50,
        'width' : 320,
        'params' : {}
      };
    `;
    document.getElementById('mobile-ad').appendChild(mobileAdScript);

    const desktopAdScript = document.createElement('script');
    desktopAdScript.type = 'text/javascript';
    desktopAdScript.innerHTML = `
      atOptions = {
        'key' : '0e2f925c5683c087269043e090944d90',
        'format' : 'iframe',
        'height' : 600,
        'width' : 160,
        'params' : {}
      };
    `;
    document.getElementById('desktop-ad').appendChild(desktopAdScript);
  }, []);

  return (
    <div className="ai-chat-body">
      <div className="ai-chat-container">
        <Helmet>
          <link rel="icon" href="../assets/icons/favicon.png" />
        </Helmet>

        <div className="ai-chat-left-section">
          <div id="mobile-ad"></div>
          <div id="desktop-ad"></div>
        </div>

        <div className="ai-chat-mid-section">
          <div className="ai-chat-heading">
            <div className="qamata-logo-container">
              <div id="qamata-logo">
                <img src={qamataLogo}/>
              </div>

              <div id="qamata-title">
                <h1>Qamata</h1>
              </div>
            </div>

            <div id="ai-chat-intro">
              <p>{displayedText}</p>
            </div>
          </div>

          <div className="response-container">
            {/*<h2>Chat History:</h2>*/}
            <div className="response-output">
              {chatHistory.map((message, index) => (
                <div key={index} className={`message ${message.role}`}>
                  <strong>{message.role === 'user' ? 'You' : 'Qamata'}</strong>

                  <ReactMarkdown
                    components={{
                      code({ node, inline, className, children, ...props }) {
                        const match = /language-(\w+)/.exec(className || '');
                        const codeContent = String(children).replace(/\n$/, '');
                        return !inline ? (
                          <div className="code-block-container">
                            <SyntaxHighlighter
                              children={codeContent}
                              style={oneDark}
                              language={match ? match[1] : ''}
                              PreTag="div"
                              {...props}
                            />
                            <button
                              className="copy-button"
                              onClick={() => copyToClipboard(codeContent)}
                            >
                              Copy Code
                            </button>
                          </div>
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      }
                    }}
                    className="markdown-content"
                  >
                    {message.content}
                  </ReactMarkdown>
                </div>
              ))}
            </div>
          </div>

          <div className="ai-chat-query-status">
            {loading ? 'Loading...' : ''}
            {error && <p className="error-message">{error}</p>}
          </div>

          <div className="ai-chat-prompt-area">
            <div id="user-input-container">
              <textarea
                type="text"
                placeholder="Ask me anything"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                ref={textareaRef}
                id="user-input"
              />
            </div>

            <div id="ai-chat-icon-container">
              <img src={aiChatIcon} id="ai-query-button" onClick={handleQuery} className="query-button" disabled={loading}/>
            </div>
          </div>
        </div>

        <div className="ai-chat-right-section">
          {/*<button onClick={() => setShowHistory(!showHistory)}>Toggle History</button>
          {showHistory && (
            <div>
              <h2>History</h2>
              <ul>
                {historyList.map((item, index) => (
                  <li key={index}>
                    <strong>{item.heading}</strong> - {item.timestamp}
                    <button onClick={() => loadHistory(item.chat)}>Load</button>
                  </li>
                ))}
              </ul>
            </div>
          )}*/}
        </div>
      </div>
    </div>
  );
};

export default AIChat;
