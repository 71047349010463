import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

const logo = require('./logo.png');

const TicStage1 = () => {
  const [board, setBoard] = useState(Array(9).fill(null));
  const [isXNext, setIsXNext] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isXNext) {
      const aiMove = getAIMove(board);
      if (aiMove !== null) {
        setTimeout(() => handleClick(aiMove), 1000); // Add a delay of 1 second
      }
    }
  }, [isXNext]);

  const handleClick = (index) => {
    if (board[index] || calculateWinner(board)) return;

    const newBoard = board.slice();
    newBoard[index] = isXNext ? 'X' : 'O';
    setBoard(newBoard);
    setIsXNext(!isXNext);
  };

  const getAIMove = (board) => {
    const availableMoves = board.map((value, index) => (value === null ? index : null)).filter((value) => value !== null);
    return availableMoves[Math.floor(Math.random() * availableMoves.length)];
  };

  const calculateWinner = (board) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];

    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (board[a] && board[a] === board[b] && board[a] === board[c]) {
        return board[a];
      }
    }
    return null;
  };

  const winner = calculateWinner(board);
  const status = winner ? `Winner: ${winner}` : `Next player: ${isXNext ? 'X' : 'O'}`;

  useEffect(() => {
    if (winner === 'X') {
      const userId = localStorage.getItem('userId');
      if (userId) {
        axios.post('https://mjondol.co.za:4000/updateStage', { userId, stage: 1 })
          .then(response => {
            console.log(response.data);
            setTimeout(() => {
              navigate('/games/tic-tac-toe/stage2');
            }, 2000); // Add a delay before moving to the next stage
          })
          .catch(error => {
            console.error('Error updating stage:', error);
          });
      } else {
        navigate('/games/tic-tac-toe/stage2'); // Bypass DB logic and proceed to next stage if user ID is null
      }
    }
  }, [winner, navigate]);

  const handleReset = () => {
    setBoard(Array(9).fill(null));
    setIsXNext(true);
  };

  return (
    <div className="tic-tac-toe">

      {/* Add a header */}
      <div className="tic-tac-toe-navigation">
        <div id="tic-tac-toe-exit">
          <p><Link to="/games">Home</Link></p>
        </div>

        <div id="tic-tac-toe-logo-container">
          <img src={logo} id="tic-tac-toe-logo" alt="Logo" />
        </div>

        <div id="navigation-space-filler">
        </div>
      </div>

      {/* Add body of game */}
      <div className="tic-tac-toe-center">

        {/* Display stages of the game */}
        <div className="tic-tac-toe-stages-display">
          <p id="tic-tac-toe-stage-label">Stage</p>
          <p id="tic-tac-toe-stage-active">One</p>
          <p id="tic-tac-toe-stage-inactive">Two</p>
          <p id="tic-tac-toe-stage-inactive">Three</p>
          <p id="tic-tac-toe-stage-inactive">Four</p>
          <p id="tic-tac-toe-stage-inactive">Five</p>
        </div>

        <div className="status">{status}</div>
        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, 100px)', gridTemplateRows: 'repeat(3, 100px)', gap: '5px' }}>
          {board.map((value, index) => (
            <button key={index} className="square" onClick={() => handleClick(index)} style={{ width: '100%', height: '100%', fontSize: '24px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {value}
            </button>
          ))}
        </div>

        <button className="reset-button" onClick={handleReset} style={{ marginTop: '20px', padding: '10px 20px', fontSize: '16px', cursor: 'pointer' }}>Reset</button>
      </div>
    </div>
  );
};

export default TicStage1;
