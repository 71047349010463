import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import './App.css';

// Import the main components
import CookiePolicy from './components/CookiePolicy';
import PrivacyPolicy from './components/PrivacyPolicy';
import Disclaimer from './components/Disclaimer';

import Navigation from './components/Navigation';
import BlogNavigation from './components/blog/BlogNavigation'; // Import BlogNavigation
import Home from './components/Home';
import MjondolSocial from './components/MjondolSocial';
import Tools from './components/Tools';
import Games from './components/Games';
import Services from './components/Services';
import Pricing from './components/Pricing';
import ContactUs from './components/ContactUs';
import AboutUs from './components/AboutUs';
import Blog from './components/blog/Blog';
import BlogAdmin from './components/blog/BlogAdmin';
import CreatePost from './components/blog/CreatePost';
import BlogList from './components/blog/BlogList';
import EditPost from './components/blog/EditPost';
import AboutBlog from './components/blog/AboutBlog';
import Article from './components/blog/Article';
import BlogContact from './components/blog/BlogContact';
import Footer from './components/Footer';
import Modal from './components/Modal';
import LoginForm from './components/LoginForm';

// Import the tools scripts
import AIImageEditor from './components/tools/AIImageEditor';
import AIImageCreator from './components/tools/AIImageCreator';
import AISlideCreator from './components/tools/AISlideCreator';
import AIMusicCreator from './components/tools/AIMusicCreator';
import AIRemoveBackground from './components/tools/AIRemoveBackground';
import AIChat from './components/tools/AIChat';

// Import the games scripts
import Polymath from './components/games/polymath/Polymath';
import HelpPolymath from './components/games/polymath/HelpPolymath';
import AboutPolymath from './components/games/polymath/AboutPolymath';

import TicTacToe from './components/games/tic-tac-toe/TicTacToe';
import TicStage1 from './components/games/tic-tac-toe/TicStage1';
import TicStage2 from './components/games/tic-tac-toe/TicStage2';
import TicStage3 from './components/games/tic-tac-toe/TicStage3';
import TicStage4 from './components/games/tic-tac-toe/TicStage4';
import TicStage5 from './components/games/tic-tac-toe/TicStage5';

const mjondolSocialLogo = require('./assets/icons/logoFlat.png');

function App() {
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const loggedIn = localStorage.getItem('loggedIn');
    const username = localStorage.getItem('username');
    const userId = localStorage.getItem('userId');

    // Log the details being read from local storage
    console.log('Reading from local storage:');
    console.log('loggedIn:', loggedIn);
    console.log('username:', username);
    console.log('userId:', userId);

    if (loggedIn === 'true' && username) {
      setUser(username);
    }
  }, []);

  useEffect(() => {
    if (!user) {
      localStorage.removeItem('userId');
      localStorage.removeItem('username');
      localStorage.removeItem('loggedIn');

      // Log the details being cleared from local storage
      console.log('Clearing local storage:');
      console.log('userId:', localStorage.getItem('userId'));
      console.log('username:', localStorage.getItem('username'));
      console.log('loggedIn:', localStorage.getItem('loggedIn'));
    }
  }, [user]);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleLogin = (username, userId) => {
    setUser(username);
    setShowModal(false);
    localStorage.setItem('userId', userId);
    localStorage.setItem('username', username);
    localStorage.setItem('loggedIn', 'true');

    // Log the details being saved to local storage
    console.log('Saving to local storage:');
    console.log('userId:', userId);
    console.log('username:', username);
    console.log('loggedIn:', 'true');
  };

  const handleLogout = async () => {
    const userId = localStorage.getItem('userId');

    try {
      const response = await fetch('https://mjondol.co.za:4000/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });

      const data = await response.json();

      if (response.ok) {
        localStorage.removeItem('userId');
        localStorage.removeItem('username');
        localStorage.removeItem('loggedIn');

        // Log the details being cleared from local storage
        console.log('Clearing local storage:');
        console.log('userId:', localStorage.getItem('userId'));
        console.log('username:', localStorage.getItem('username'));
        console.log('loggedIn:', localStorage.getItem('loggedIn'));

        setUser(null);
        alert('Logged out successfully');
      } else {
        alert(data.message || 'An error occurred during logout');
      }
    } catch (error) {
      console.error('Error during logout:', error);
      alert('An error occurred during logout');
    }
  };

  // Check if the current path is greater than "/games"
  const isGreaterThanGames = location.pathname.startsWith('/games/') && location.pathname !== '/games';

  // Check if the current path is exactly "/blog" or not
  const isBlogPath = location.pathname === '/blog';
  const isAboutBlogPath = location.pathname === '/about-blog';
  const isBlogAdminPath = location.pathname === '/blog-admin/blog-list';
  const isBlogListPath = location.pathname === '/blog-admin/create-post';
  const isEditPostPath = location.pathname === '/blog-admin/edit-post';
  const isPolymath = location.pathname === '/games/polymath';
  const isArticlePath = location.pathname.startsWith('/article/');

  return (
    <div className="App">
      {!isGreaterThanGames && !isBlogPath && !isAboutBlogPath && !isBlogAdminPath && !isBlogListPath && !isEditPostPath && !isArticlePath && !isArticlePath && <Navigation handleOpenModal={handleOpenModal} user={user} handleLogout={handleLogout} />}

      {!isGreaterThanGames && !isBlogAdminPath && !isBlogListPath && !isEditPostPath && !isArticlePath && <div id="emptyBlock"></div>}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/mjondol-social" element={<MjondolSocial />} />

        <Route path="/tools" element={<Tools />}>
          <Route path="ai-image-editor" element={<AIImageEditor />} />
          <Route path="ai-image-creator" element={<AIImageCreator />} />
          <Route path="ai-slide-creator" element={<AISlideCreator />} />
          <Route path="ai-music-creator" element={<AIMusicCreator />} />
          <Route path="ai-remove-background" element={<AIRemoveBackground />} />
          <Route path="ai-chat" element={<AIChat />} />
        </Route>

        <Route path="/games" element={<Games />}>
          <Route path="polymath" element={<Polymath />}>
            <Route path="about-polymath" element={<AboutPolymath />} />
            <Route path="help-polymath" element={<HelpPolymath />} />
          </Route>
          <Route path="tic-tac-toe" element={<TicTacToe />} />
          <Route path="tic-tac-toe/stage1" element={<TicStage1 />} />
          <Route path="tic-tac-toe/stage2" element={<TicStage2 />} />
          <Route path="tic-tac-toe/stage3" element={<TicStage3 />} />
          <Route path="tic-tac-toe/stage4" element={<TicStage4 />} />
          <Route path="tic-tac-toe/stage5" element={<TicStage5 />} />
        </Route>

        <Route path="/services" element={<Services />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/about-blog" element={<AboutBlog />} />
        <Route path="/blog-contact" element={<BlogContact />} />
        <Route path="/article/:id" element={<Article />} />
        <Route path="/blog-admin" element={<BlogAdmin user={user} />}>
          <Route path="create-post" element={<CreatePost />} />
          <Route path="blog-list" element={<BlogList />} />
          <Route path="edit-post" element={<EditPost />} />
        </Route>
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/cookies" element={<CookiePolicy />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
      </Routes>
      <hr />
      {!isPolymath && <Footer />}
      <Modal show={showModal} handleClose={handleCloseModal}>
        <LoginForm handleClose={handleCloseModal} handleLogin={handleLogin} />
      </Modal>
    </div>
  );
}

function AppWithRouter() {
  return (
    <Router>
      <App />
    </Router>
  );
}

export default AppWithRouter;
