import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from 'axios';
import BlogNavigation from './BlogNavigation';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon
} from 'react-share';

const Article = () => {
  const { id } = useParams();
  const [article, setArticle] = useState(null);
  const [popularPosts, setPopularPosts] = useState([]);

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        console.log('Fetching article with ID:', id); 
        const response = await axios.get(`https://mjondol.co.za:4000/posts/${id}`);
        const articleData = response.data;
        console.log('Fetched article data:', articleData); // Debugging log
        const formattedDate = new Date(articleData.createdAt).toLocaleString('en-US', { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' });
        setArticle({ ...articleData, date: formattedDate });
      } catch (error) {
        console.error('Error fetching article:', error);
      }
    };

    if (id) {
      fetchArticle();
    } else {
      console.error('No ID provided');
    }
  }, [id]);

  useEffect(() => {
    const fetchPopularPosts = async () => {
      try {
        const response = await axios.get('https://mjondol.co.za:4000/posts');
        const postsData = response.data;
        console.log('Fetched popular posts:', postsData);
        
        // Shuffle the posts array and select 5 random posts
        const shuffledPosts = postsData.sort(() => 0.5 - Math.random());
        const selectedPosts = shuffledPosts.slice(0, 5);
        
        setPopularPosts(selectedPosts);
      } catch (error) {
        console.error('Error fetching popular posts:', error);
      }
    };

    fetchPopularPosts();
  }, []);

  if (!article) return <div>Loading...</div>;

  return (
    <div>
      <BlogNavigation />
      {/* Page Wrapper */}
      <div className="article-page-wrapper">

        <div className="article-left-sidebar">
        </div>

        {/* Content */}
        <div className="article-component-body">
  
          <div className="article-content-wrapper">
            <div className="main-content single">
              <h2 id="article-title">{article.Title}</h2>
              <p id="article-date-time">{article.date}</p>

              <div className="share-buttons-container">
                <div className="share-buttons">
                  <FacebookShareButton url={window.location.href} className="share-button">
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>
                  <TwitterShareButton url={window.location.href} className="share-button">
                    <TwitterIcon size={32} round />
                  </TwitterShareButton>
                  <LinkedinShareButton url={window.location.href} className="share-button">
                    <LinkedinIcon size={32} round />
                  </LinkedinShareButton>
                  <WhatsappShareButton url={window.location.href} className="share-button">
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                  <TelegramShareButton url={window.location.href} className="share-button">
                    <TelegramIcon size={32} round />
                  </TelegramShareButton>
                </div>
              </div>

              <p id="author-label">{article.Author ? article.Author : 'SoT'}</p>

              <hr/>
              <div className="post-content" dangerouslySetInnerHTML={{ __html: article.Content ? article.Content.replace(/<img /g, '<img class="responsive-image" ') : '' }} />

            </div>
          </div>
        </div>

        {/* Sidebar */}
        <div className="article-sidebar">
          <div className="section popular">
          <hr />
            <h1 className="section-title">Popular</h1>
          <hr />
          {popularPosts.map(post => (
            <div key={post.ArticleId} >
              <div className="popular-article-card">
                <Link to={`/article/${post.ArticleId}`} >
                  <div id="popular-article-card-image">
                    <img src={post.media} alt="oo" />
                  </div>
                  </Link>

                  <Link to={`/article/${post.ArticleId}`} >
                  <div id="popular-article-card-title">
                  <h5>{post.Title ? post.Title.substring(0, 50) + '...' : 'No Title'}</h5>
                  </div>
                  </Link>
              </div>
              <hr />
            </div>


          ))}
          </div>
        </div>
      </div>

    </div>
  );
};

export default Article;
