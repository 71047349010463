import React, { useEffect, useState } from 'react';
import { Link, Outlet, useNavigate, useLocation, Navigate } from 'react-router-dom';
import BlogAdminNavigation from './BlogAdminNavigation';
import UseUserAdminStatus from './UseUserAdminStatus';

const BlogAdmin = ({ user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAdmin, loading } = UseUserAdminStatus(user?.userId);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    if (initialLoad && location.pathname === '/blog-admin') {
      navigate('blog-list'); // Navigate to blog-list on initial component mount
      setInitialLoad(false);
    }
  }, [initialLoad, location.pathname, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!isAdmin) {
    alert('Oh, it seems you are not admin.');
    return <Navigate to="/blog" />;
  }

  return (
    <div className="blog-admin-container">
      <BlogAdminNavigation />

      <div className="blog-admin-content">
        <div className="blog-admin-left-section">
          <Link to="/blog"><button>View Blog</button></Link>
          <Link to="create-post"><button>Create Post</button></Link>
          <Link to="blog-list"><button>Blog List</button></Link>
        </div>

        <div className="blog-admin-right-section">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default BlogAdmin;
