import React from 'react';

const HelpPolymath = () => {
  return (
    <div>
      <h1>Help</h1>
    </div>
  );
};

export default HelpPolymath;