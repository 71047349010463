import { useState, useEffect } from 'react';

const useUserAdminStatus = (userId) => {
  const [isAdmin, setIsAdmin] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const response = await fetch(`https://mjondol.co.za:4000/user-status/${userId}`);
        const data = await response.json();
        setIsAdmin(data.BlogUser === 'True');
      } catch (error) {
        console.error('Error fetching user status:', error);
        setIsAdmin(false);
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserStatus();
    } else {
      setLoading(false);
    }
  }, [userId]);

  return { isAdmin, loading };
};

export default useUserAdminStatus;
