import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPolicy = () => {
	return (
		<div className="privacy-policy-body">
			<h2>PRIVACY NOTICE</h2>
			<p>Last updated 04 October 2024</p>
			<p>This Privacy Notice for Mjondol Labs ("we," "us," or "our"), describes how
			and why we might access, collect, store, use, and/or share ("process")
			your personal information when you use our services ("Services"),
			including when you:<br/>
			<span>Questions or concerns?</span> Reading this Privacy Notice will help you
			understand your privacy rights and choices. We are responsible for making
			decisions about how your personal information is processed. If you do not
			agree with our policies and practices, please do not use our Services.</p>
			<h3>SUMMARY OF KEY POINTS</h3>
			<p><span>This summary provides key points from our Privacy Notice, but you
			can find out more details about any of these topics by clicking the
			link following each key point or by using our table of contents below
			to find the section you are looking for.</span></p>
			<p><span>What personal information do we process?</span> When you visit, use, or
			navigate our Services, we may process personal information depending on
			how you interact with us and the Services, the choices you make, and the
			products and features you use.</p>
			<p><span>Do we process any sensitive personal information?</span> Some of the
			information may be considered "special" or "sensitive" in certain
			jurisdictions, for example your racial or ethnic origins, sexual orientation,
			and religious beliefs. We do not process sensitive personal information.</p>
			<p><span>Do we collect any information from third parties?</span> We may collect
			information from public databases, marketing partners, social media
			platforms, and other outside sources.</p>
			<p><span>How do we process your information?</span> We process your information to
			provide, improve, and administer our Services, communicate with you, for
			security and fraud prevention, and to comply with law. We may also
			process your information for other purposes with your consent. We process
			your information only when we have a valid legal reason to do so. Learn
			more about how we process your information.</p>
			<p><span>In what situations and with which parties do we share personal
			information?</span> We may share information in specific situations and with
			specific third parties.</p>
			<p><span>What are your rights? </span>Depending on where you are located
			geographically, the applicable privacy law may mean you have certain
			rights regarding your personal information.</p>
			<p><span>How do you exercise your rights?</span> The easiest way to exercise your
			rights is by submitting a data subject access request, or by contacting us.
			We will consider and act upon any request in accordance with applicable
			data protection laws.</p>
			<p>Want to learn more about what we do with any information we
			collect? Review the Privacy Notice in full.</p>

			<h3>TABLE OF CONTENTS</h3>
			<ol>
				<li>WHAT INFORMATION DO WE COLLECT?</li>
				<li>HOW DO WE PROCESS YOUR INFORMATION?</li>
				<li>WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL
				INFORMATION?</li>
				<li>DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</li>
				<li>HOW DO WE HANDLE YOUR SOCIAL LOGINS?</li>
				<li>IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</li>
				<li>HOW LONG DO WE KEEP YOUR INFORMATION?</li>
				<li>DO WE COLLECT INFORMATION FROM MINORS?</li>
				<li>WHAT ARE YOUR PRIVACY RIGHTS?</li>
				<li>CONTROLS FOR DO-NOT-TRACK FEATURES</li>
				<li>DO WE MAKE UPDATES TO THIS NOTICE?</li>
				<li>HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
				<li>HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE
				COLLECT FROM YOU?</li>
			</ol>
			<h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
			<h4>Personal information you disclose to us</h4>
			<p><span>In Short:</span> We collect personal information that you provide to us.
			We collect personal information that you voluntarily provide to us when you
			register on the Services, express an interest in obtaining information about
			us or our products and Services, when you participate in activities on the
			Services, or otherwise when you contact us.</p>
			<p><span>Sensitive Information.</span> We do not process sensitive information.</p>
			<p>All personal information that you provide to us must be true, complete, and
			accurate, and you must notify us of any changes to such personal
			information.</p>
			<h3>Information automatically collected</h3>
			<p><span>In Short:</span> Some information — such as your Internet Protocol (IP) address
			and/or browser and device characteristics — is collected automatically
			when you visit our Services.</p>
			<p>We automatically collect certain information when you visit, use, or
			navigate the Services. This information does not reveal your specific
			identity (like your name or contact information) but may include device and
			usage information, such as your IP address, browser and device
			characteristics, operating system, language preferences, referring URLs,
			device name, country, location, information about how and when you use
			our Services, and other technical information. This information is primarily
			needed to maintain the security and operation of our Services, and for our
			internal analytics and reporting purposes.</p>
			<p>Like many businesses, we also collect information through cookies and
			similar technologies.</p>
			<h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
			<p><span>In Short:</span> We process your information to provide, improve, and administer
			our Services, communicate with you, for security and fraud prevention, and
			to comply with law. We may also process your information for other
			purposes with your consent.</p>
			<p><span>We process your personal information for a variety of reasons,
			depending on how you interact with our Services, including:</span></p>
			<h3>3. WHEN AND WITH WHOM DO WE SHARE YOUR
			PERSONAL INFORMATION?</h3>
			<p><span>In Short:</span> We may share information in specific situations described in this
			section and/or with the following third parties.</p>
			<p>We may need to share your personal information in the following situations:</p>
			<ul>
				<li><span>Business Transfers.</span> We may share or transfer your information in
				connection with, or during negotiations of, any merger, sale of
				company assets, financing, or acquisition of all or a portion of our
				business to another company.</li>
				<li><span>Affiliates.</span> We may share your information with our affiliates, in
				which case we will require those affiliates to honor this Privacy
				Notice. Affiliates include our parent company and any subsidiaries,
				joint venture partners, or other companies that we control or that are
				under common control with us.</li>
				<li><span>Business Partners.</span> We may share your information with our
				business partners to offer you certain products, services, or
				promotions.</li>
			</ul>
			<h3>4. DO WE USE COOKIES AND OTHER TRACKING
			TECHNOLOGIES?</h3>
			<p><span>In Short:</span> We may use cookies and other tracking technologies to collect
			and store your information.</p>
			<p>We may use cookies and similar tracking technologies (like web beacons
			and pixels) to gather information when you interact with our Services.
			Some online tracking technologies help us maintain the security of our
			Services, prevent crashes, fix bugs, save your preferences, and assist with
			basic site functions.</p>
			<p>We also permit third parties and service providers to use online tracking
			technologies on our Services for analytics and advertising, including to help
			manage and display advertisements, to tailor advertisements to your
			interests, or to send abandoned shopping cart reminders (depending on
			your communication preferences). The third parties and service providers
			use their technology to provide advertising about products and services
			tailored to your interests which may appear either on our Services or on
			other websites.</p>
			<p>Specific information about how we use such technologies and how you can
			refuse certain cookies is set out in our Cookie Notice.</p>
			<h3>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h3>
			<p><span>In Short:</span> If you choose to register or log in to our Services using a social
			media account, we may have access to certain information about you.</p>
			<p>Our Services offer you the ability to register and log in using your third-party social media account details (like your Facebook or X logins). Where
			you choose to do this, we will receive certain profile information about you
			from your social media provider. The profile information we receive may
			vary depending on the social media provider concerned, but will often
			include your name, email address, friends list, and profile picture, as well
			as other information you choose to make public on such a social media
			platform.</p>
			<p>We will use the information we receive only for the purposes that are
			described in this Privacy Notice or that are otherwise made clear to you
			on the relevant Services. Please note that we do not control, and are not
			responsible for, other uses of your personal information by your third-party
			social media provider. We recommend that you review their privacy notice
			to understand how they collect, use, and share your personal information,
			and how you can set your privacy preferences on their sites and apps.</p>
			<h3>6. IS YOUR INFORMATION TRANSFERRED
			INTERNATIONALLY?</h3>
			<p><span>In Short:</span> We may transfer, store, and process your information in countries
			other than your own.</p>
			<p>Our servers are located in. If you are accessing our Services from outside,
			please be aware that your information may be transferred to, stored by, and
			processed by us in our facilities and in the facilities of the third parties with
			whom we may share your personal information (see "WHEN AND WITH
			WHOM DO WE SHARE YOUR PERSONAL INFORMATION?" above),
			in and other countries.</p>
			<p>If you are a resident in the European Economic Area (EEA), United
			Kingdom (UK), or Switzerland, then these countries may not necessarily
			have data protection laws or other similar laws as comprehensive as those
			in your country. However, we will take all necessary measures to protect
			your personal information in accordance with this Privacy Notice and
			applicable law.</p>
			<h3>7. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
			<p><span>In Short:</span> We keep your information for as long as necessary to fulfill the
			purposes outlined in this Privacy Notice unless otherwise required by law.</p>
			<p>We will only keep your personal information for as long as it is necessary
			for the purposes set out in this Privacy Notice, unless a longer retention
			period is required or permitted by law (such as tax, accounting, or other
			legal requirements).</p>
			<p>When we have no ongoing legitimate business need to process your
			personal information, we will either delete or anonymize such information,
			or, if this is not possible (for example, because your personal information
			has been stored in backup archives), then we will securely store your
			personal information and isolate it from any further processing until deletion
			is possible.</p>
			<h3>8. DO WE COLLECT INFORMATION FROM
			MINORS?</h3>
			<p><span>In Short:</span> We do not knowingly collect data from or market to children
			under 18 years of age.</p>
			<p>We do not knowingly collect, solicit data from, or market to children under
			18 years of age, nor do we knowingly sell such personal information. By
			using the Services, you represent that you are at least 18 or that you are
			the parent or guardian of such a minor and consent to such minor
			dependent’s use of the Services. If we learn that personal information from
			users less than 18 years of age has been collected, we will deactivate the
			account and take reasonable measures to promptly delete such data from
			our records. If you become aware of any data we may have collected from
			children under age 18, please contact us at info@mjondol.co.za.</p>
			<h3>9. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
			<p><span>In Short:</span> You may review, change, or terminate your account at any time,
			depending on your country, province, or state of residence.</p>
			<p><span>Withdrawing your consent:</span> If we are relying on your consent to process
			your personal information, which may be express and/or implied consent
			depending on the applicable law, you have the right to withdraw your
			consent at any time. You can withdraw your consent at any time by
			contacting us by using the contact details provided in the section "HOW
			CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
			However, please note that this will not affect the lawfulness of the
			processing before its withdrawal nor, when applicable law allows, will it
			affect the processing of your personal information conducted in reliance on
			lawful processing grounds other than consent.</p>
			<h4>Account Information</h4>
			<p>If you would at any time like to review or change the information in your
			account or terminate your account, you can:
			Upon your request to terminate your account, we will deactivate or delete
			your account and information from our active databases. However, we may
			retain some information in our files to prevent fraud, troubleshoot problems,
			assist with any investigations, enforce our legal terms and/or comply with
			applicable legal requirements.</p>
			<h3>10. CONTROLS FOR DO-NOT-TRACK FEATURES</h3>
			<p>Most web browsers and some mobile operating systems and mobile
			applications include a Do-Not-Track ("DNT") feature or setting you can
			activate to signal your privacy preference not to have data about your
			online browsing activities monitored and collected. At this stage, no uniform
			technology standard for recognizing and implementing DNT signals has
			been finalized. As such, we do not currently respond to DNT browser
			signals or any other mechanism that automatically communicates your
			choice not to be tracked online. </p>
			<h3>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
			<p><span>In Short:</span> Yes, we will update this notice as necessary to stay compliant
			with relevant laws.</p>
			<p>We may update this Privacy Notice from time to time. The updated version
			will be indicated by an updated "Revised" date at the top of this Privacy
			Notice. If we make material changes to this Privacy Notice, we may notify
			you either by prominently posting a notice of such changes or by directly
			sending you a notification. We encourage you to review this Privacy Notice
			frequently to be informed of how we are protecting your information.</p>
			<h3>12. HOW CAN YOU CONTACT US ABOUT THIS
			NOTICE?</h3>

			<p>If you have questions or comments about this notice, you may contact us
			by email at: info@mjondol.co.za</p>

			<h3>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE
			THE DATA WE COLLECT FROM YOU?</h3>
			<p>Based on the applicable laws of your country, you may have the right to
			request access to the personal information we collect from you, details
			about how we have processed it, correct inaccuracies, or delete your
			personal information. You may also have the right to withdraw your consent
			to our processing of your personal information. These rights may be limited
			in some circumstances by applicable law. To request to review, update, or
			delete your personal information, please fill out and submit a <a href="https://app.termly.io/notify/f0b246cd-156a-42ef-bed9-fdde7833af2d">data subject
			access request.</a></p>
		</div>
	);
}

export default PrivacyPolicy;
