import React from 'react';
import BlogNavigation from './BlogNavigation';

const AboutBlog = () => {
  return (
    <div className="about-blog-content">
      <BlogNavigation />

      <div className="about-blog-content-container">
        <h2>Welcome to the State of Technology Blog!</h2>

        <p>
        Our blog is your go-to source for insights, trends, and news in the world of technology. We're passionate about sharing our knowledge and expertise with you, whether you're a seasoned tech enthusiast or just starting your journey.
        </p>

        <h3>What to Expect:</h3>
        <ul>
          <li><span>Industry news and updates:</span> Stay informed about the latest developments in technology, AI, machine learning, and more.</li>
          <li><span>Expert insights:</span> Learn from our team of experts as they share their knowledge and experiences.</li>
          <li><span>Practical tips and tutorials:</span> Get hands-on guidance on using technology to solve real-world problems.</li>
          <li><span>Thought-provoking articles:</span> Explore the ethical and societal implications of technology.</li>
        </ul>

        <h3>Our Commitment:</h3>
        <p>
        At Mjon dol Labs, we're committed to providing valuable and informative content that helps you stay ahead of the curve. We believe that technology has the power to transform lives, and we're dedicated to sharing our knowledge and passion with you.
        </p>

        <p>
        <span>Thank you for visiting our blog!</span> We hope you'll find our content informative, engaging, and inspiring.
        </p>
      </div>
    </div>
  );
};

export default AboutBlog;
