import React, { useEffect } from 'react';
import { FaFacebook, FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const ContactUs = () => {
  useEffect(() => {
    if (!document.querySelector('script[src="https://www.cognitoforms.com/f/seamless.js"]')) {
      const script = document.createElement('script');
      script.src = 'https://www.cognitoforms.com/f/seamless.js';
      script.setAttribute('data-key', 'fNoK9VRiKUuLHzc05BzfEg');
      script.setAttribute('data-form', '1');
      script.async = true;
      document.getElementById('contact-form-container').appendChild(script);
    }
  }, []);

  return (
    <div className="contacts-body-content">
      <div className="contacts-body-content-container">

        <div id="contact-address">
          <p>
            <span>Mjondol Labs</span> <br/>
            Thornhill Office Park <br/>
            84 Bekker Rd, Vorna Valley, <br/>
            Midrand, 1686 <br/>
            Gauteng, South Africa
          </p>

          <p>
            <span>Phone:</span> +27 63 334 6169 <br/>
            <span>WhatsApp:</span> +27 63 334 6169 <br/>
            <span>Email:</span> info@mjondol.co.za / sales@mjondol.co.za
          </p>
        </div>

        <div className="contact-social-media-icons">
          <a href="https://web.facebook.com/MjondolLabs/" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
          <a href="https://x.com/MjondolLabs" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
          <a href="https://www.linkedin.com/company/105075824/" target="_blank"><FaLinkedin /></a>
          {/*<a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>*/}
        </div>

        <hr/>

        <h3>Hours of Operation:</h3>

        <ul>
          <li>Monday - Friday: 9:00 AM - 5:00 PM SAST</li>
          <hr/>
          <li>Tuesday - Friday: 9:00 AM - 5:00 PM SAST</li>
          <hr/>
          <li>Wednesday - Friday: 9:00 AM - 5:00 PM SAST</li>
          <hr/>
          <li>Thursday - Friday: 9:00 AM - 5:00 PM SAST</li>
          <hr/>
          <li>Friday - Friday: 9:00 AM - 2:30 PM SAST</li>
        </ul>

        <hr/>

        <p>
          <span>Have a question or need assistance?</span> Feel free to contact us using the form below or reach out to us directly. We're here to help!
        </p>

        <div id="contact-form-container" className="contact-container">
          {/* The form will be embedded here */}
        </div>

      </div>
    </div>
  );
};

export default ContactUs;
