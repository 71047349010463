import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import axios from "axios";

const CreatePost = () => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [author, setAuthor] = useState("");
  const [media, setMedia] = useState(null);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post("https://mjondol.co.za:4000/create-post", {
        title: title,
        content: content,
        author: author,
        media: media,
      });
      alert("Blog added successfully!");
      setTitle("");
      setContent("");
      setAuthor("");
      setMedia(null);
    } catch (error) {
      console.error("Error adding blog: ", error);
    }
  };

  // Custom Upload Adapter for Media Upload
class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          const formData = new FormData();
          formData.append("file", file);
          axios
            .post("https://mjondol.co.za:4000/upload", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              const url = response.data.url;
              console.log('Image uploaded, URL:', url); // Add this line
              resolve({
                default: url,
              });
            })
            .catch((error) => {
              console.error('Error uploading image:', error); // Add this line
              reject(error);
            });
        })
    );
  }
}


  function MyCustomUploadAdapterPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return new MyUploadAdapter(loader);
    };
  }

  const extractTitleAndMedia = (data) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, "text/html");
    const heading = doc.querySelector("h2");
    const image = doc.querySelector("img");

    if (heading) {
      setTitle(heading.textContent);
    }

    if (image) {
      setMedia(image.src);
    }
  };

  return (
    <div className="create-post">
      <h2>Add a New Blog</h2>
      <form onSubmit={handleSubmit}>
        <label>Blog Title:</label>
        <input
          type="text"
          name="title"
          placeholder="Enter blog title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        <label>Blog Content:</label>
        <CKEditor
          editor={ClassicEditor}
          data={content}
          onChange={(event, editor) => {
            const data = editor.getData();
            setContent(data);
            extractTitleAndMedia(data);
          }}
          config={{
            extraPlugins: [MyCustomUploadAdapterPlugin],
            image: {
              toolbar: [
                "imageTextAlternative",
                "imageStyle:full",
                "imageStyle:side",
                "resizeImage:50",
                "resizeImage:75",
                "resizeImage:original",
                "cropImage",
              ],
            },
          }}
        />
        <label>Blog Author:</label>
        <input
          type="text"
          name="author"
          placeholder="Author name"
          value={author}
          onChange={(e) => setAuthor(e.target.value)}
        />
        <button type="submit">Create</button>
      </form>
    </div>
  );
};

export default CreatePost;
